import React, { ElementType, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import * as icons from '@mui/icons-material';
import { BottomNavigation, BottomNavigationAction } from '@mui/material';

import { menus } from '../../shared/constants';
import config from './Footer.component.config';
import { styles } from './Footer.component.styles';

const allIcons: Record<string, ElementType> = icons;

const Footer: React.FC = function () {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const path: string = pathname.split('/')[1];
  const menuPath = menus[path];

  const pageIndex = useMemo(() => {
    if (!menuPath) return 0;
    if (menuPath.path === pathname) {
      return 1;
    }

    if (menuPath.subs?.length) {
      return menuPath.subs.findIndex(item => pathname.includes(item.path)) + 1;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, path]);

  return (
    <div css={styles.wrapper}>
      <BottomNavigation
        showLabels
        value={pageIndex}
        css={styles.bottomNavigation}
        className="transition-all"
      >
        <BottomNavigationAction
          component={Link}
          to="/"
          css={styles.bottomNavigationAction}
          label={t('Home')}
          icon={<icons.Home />}
        />
        {menuPath
          ? menuPath.subs?.map((menu, i) => {
              const Icon: React.ElementType = allIcons[menu.icon];
              return (
                <BottomNavigationAction
                  component={Link}
                  key={i}
                  to={menuPath.path + menu.path}
                  css={styles.bottomNavigationAction}
                  label={t(menu.label)}
                  icon={<Icon />}
                />
              );
            })
          : null}
      </BottomNavigation>
    </div>
  );
};

Footer.defaultProps = config.defaultProps;
Footer.displayName = config.displayName;
Footer.propTypes = config.propTypes;

export default Footer;
