import { useAuthStore } from './../core/zustand/store/authStore/authStore';
import moment from 'moment';

import { FormatDateType, LanguageKey } from './types';

export const BASE_URL = '';

export const TIMEOUT_MS = 20000;

type MenusType = {
  id: string;
  label: string;
  path: string;
  icon: string;
  subs?: MenusType[];
};

export const menus: Record<string, MenusType> = {
  vendor: {
    id: 'vendor',
    label: 'Vendor',
    path: '/vendor',
    icon: 'Send',
    subs: [
      {
        id: 'send',
        label: 'Send',
        path: '/send',
        icon: 'Send',
      },
      {
        id: 'history',
        label: 'History',
        path: '/history',
        icon: 'ReceiptLong',
      },
    ],
  },
  backoffice: {
    id: 'backoffice',
    label: 'Backoffice',
    path: '/backoffice',
    icon: 'Home',
    subs: [
      {
        id: 'dashboard',
        label: 'Dashboard',
        path: '/dashboard',
        icon: 'Dashboard',
      },
      {
        id: 'balances',
        label: 'Balances',
        path: '/balances',
        icon: 'AccountBalanceWallet',
      },
    ]
  }
};

export const toCapitalize = (str: string) =>
  str ? str.charAt(0).toUpperCase() + str.slice(1).toLowerCase() : str;

export const formatDate = ({
  date = new Date().toISOString(),
  format = 'DD MMM yyyy',
}: FormatDateType = {}): string => {
  const dateIsValid = (d: string) => {
    return !Number.isNaN(new Date(d).getTime());
  };

  return moment(
    new Date(dateIsValid(date) ? date : new Date().toISOString()),
  ).format(format);
};

export const money = (str: number) =>
  new Intl.NumberFormat('id-ID', {
    style: 'currency',
    currency: 'IDR',
    maximumFractionDigits: 0,
  }).format(str);
  
export const IDRformat = (amount: number) => {
  const _amount = Math.floor(amount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return `Rp${_amount}`;
}

export const USDformat = (amount: number) => {
  return amount.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
}

export const tabsRecord: Record<string, string> = {
  '0': 'processing',
  '1': 'complete',
};

export const toTokenBidr = (str: string, format = '_BIDR') =>
  str.toUpperCase() + format;

export const getToken = () => {
  const { accessToken } = useAuthStore();
  return accessToken;
};

export const getAxiosConfigWithBearerToken = () => {
  const accessToken = getToken();
  return {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
};

export const truncate = (fullStr: string, strLen = 20, separator?: string) => {
  if (fullStr?.length <= strLen) return fullStr;

  separator = separator || '...';

  const sepLen = separator.length,
    charsToShow = strLen - sepLen,
    frontChars = Math.ceil(charsToShow / 2),
    backChars = Math.floor(charsToShow / 2);

  return (
    fullStr?.substr(0, frontChars) +
    separator +
    fullStr?.substr(fullStr.length - backChars)
  );
};

type Language = {
  key: LanguageKey;
  name: keyof typeof LanguageKey;
};
export const languageList: Language[] = [
  {
    key: LanguageKey.English,
    name: 'English',
  },
  {
    key: LanguageKey.Indonesian,
    name: 'Indonesian',
  },
];
