import { Paper, Divider, Skeleton } from "@mui/material";
import Container from "../../../components/Container/Container.component"
import TradeVolumeChart from "../../../components/Charts/EnvoyPortalVolumeChart";
import { useFetch } from "../../../shared/hooks";
import { API } from "../../../shared/api";
import { IDRformat, USDformat } from "../../../shared/constants";
import { GMSGuildsTxCount } from "src/shared/types";
import { Fragment } from "react";

const monthlyTradeVolume = [200, 190, 220];
const months = ['Jul', 'Aug', 'Sept'];


const BODashboard = () => {

  const { data: transactions, isLoading: transactionsLoading } = useFetch('transactions-stats', API.DASHBOARD_TRANSACTIONS_STATS);
  const { data: trades, isLoading: tradesLoading } = useFetch('trades-stats', API.DASHBOARD_TRADES_STATS);
  const { data: gms, isLoading: gmsLoading } = useFetch('gms-stats', API.DASHBOARD_GMS_STATS);

  return (
    <Container>
      <div className="my-4 text-white">
        <Divider light>
          <h2 className="text-4xl font-VT323">Bridge</h2>
        </Divider>
        
        <div className="mt-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2">

          {/* Trade Card */}
          <div className="flex flex-col h-40 justify-center space-y-10 rounded-lg bg-gradient-to-br from-orange-200/75 to-sky-400 p-2">
          <div className="grid grid-cols-2 gap-2 px-4 items-baseline">
            <h3 className="text-xl font-bold">Total Trades</h3>
            <p className="text-4xl font-VT323 text-right">{transactionsLoading ? <Skeleton/> : transactions.counts.total}</p>
            <h4 className="text-xl">Sell Trades</h4>
            <p className="text-3xl font-VT323 text-right">{transactionsLoading ? <Skeleton/> : transactions.counts.total_sell}</p>
            <h4 className="text-xl">Buy Trades</h4>
            <p className="text-3xl font-VT323 text-right">{transactionsLoading ? <Skeleton/> : transactions.counts.total_buy}</p>
          </div>
          </div>

          {/* Volume Card */}
          <div className="flex flex-col h-40 justify-center space-y-10 rounded-lg bg-gradient-to-br from-lime-200/75 to-sky-400 p-2">
            <div className="grid grid-cols-2 gap-2 px-2 items-baseline">
              <h3 className="text-xl font-bold col-span-2">Total Trade Volume</h3>
              <h4 className="text-sm">Average (Transit)</h4>
              <p className="text-3xl font-VT323 text-right">{tradesLoading ? <Skeleton/> : USDformat(trades.volume.transit)}</p>
              <h4 className="text-sm">Accept & Disburse</h4>
              <p className="text-2xl font-VT323 text-right">{tradesLoading ? <Skeleton/> : IDRformat(trades.volume.accept + trades.volume.disburse)}</p>
            </div>
          </div>

          {/* Accumulated Fee Card */}
          <div className="flex flex-col h-40 justify-center space-y-10 rounded-lg bg-gradient-to-br from-lime-200/75 to-indigo-400 p-2">
            <div className="grid grid-cols-2 gap-2 px-2 items-baseline">
              <h3 className="text-xl font-bold col-span-2">Accumulated Fee</h3>
              <h4 className="text-sm">Gross</h4>
              <p className="text-3xl font-VT323 text-right">{tradesLoading ? <Skeleton/> : IDRformat(trades.fees.total_base_fee + trades.fees.total_pg_fee)}</p>
              <h4 className="text-sm">Nett</h4>
              <p className="text-3xl font-VT323 text-right">{tradesLoading ? <Skeleton/> : IDRformat(trades.fees.total_base_fee * 0.7)}</p>
            </div>
          </div>

          {/* Costs Card */}
          <div className="flex flex-col h-40 justify-center space-y-10 rounded-lg bg-gradient-to-br from-orange-300/75 to-pink-400 p-2">
            <div className="grid grid-cols-2 gap-2 px-2 items-baseline">
              <h4 className="text-sm">PG Costs</h4>
              <p className="text-3xl font-VT323 text-right">{tradesLoading ? <Skeleton/> : IDRformat(trades.fees.total_pg_fee)}</p>
              <h4 className="text-sm">Network Costs</h4>
              <p className="text-3xl font-VT323 text-right">$123</p>
              <h4 className="text-sm">Infra Costs</h4>
              <p className="text-3xl font-VT323 text-right">$123</p>
            </div>
          </div>

          {/* Users Card */}
          <div className="flex flex-col h-40 justify-center space-y-10 rounded-lg bg-gradient-to-br from-orange-200/75 to-sky-400 p-2">
            <div className="grid grid-cols-2 gap-2 px-4 items-baseline">
              <h3 className="text-xl font-bold">MAU</h3>
              <p className="text-4xl font-VT323 text-right">{transactionsLoading ? <Skeleton/> : transactions.users.unique_users}</p>
              <h4 className="text-xl">Average Size</h4>
              <p className="text-3xl font-VT323 text-right">{transactionsLoading ? <Skeleton/> : IDRformat(transactions.users.avg_amount)}</p>
              <h4 className="text-xl">Total Users</h4>
              <p className="text-3xl font-VT323 text-right">{transactionsLoading ? <Skeleton/> : transactions.totalUsers}</p>
            </div>
          </div>
        </div>

        {/* Bridge Monthly Trade Volume Chart */}
        <Paper elevation={4} className='mt-4 p-4'>
          <TradeVolumeChart key={Date.now()} data={monthlyTradeVolume} labels={months} />
        </Paper>

        <Divider sx={{mt: 4}} light>
          <h2 className="text-4xl font-VT323">Accept</h2>
        </Divider>
        
        <div className="mt-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2">

          {/* Disbursements */}
          <div className="flex flex-col h-40 justify-center space-y-10 rounded-lg bg-gradient-to-br from-orange-200/75 to-sky-400 p-2">
            <div className="grid grid-cols-2 gap-2 px-4 items-baseline">
              <h3 className="text-xl font-bold">Disbursements</h3>
              <p className="text-3xl font-VT323 text-right">{gmsLoading ? <Skeleton/> : gms.totalCount}</p>
              {gms && gms.guilds && gms.guilds.map((item: GMSGuildsTxCount) => {
                return (
                  <Fragment key={item.guild}>
                    <h4 className="text-md">{item.guild.toUpperCase()}</h4>
                    <p className="text-2xl font-VT323 text-right">{item.count}</p>
                  </Fragment>
                )
              })}
            </div>
          </div>

          {/* Accumulated Fee Card */}
          <div className="flex flex-col h-40 justify-center rounded-lg bg-gradient-to-br from-lime-200/75 to-indigo-400 p-2">
            <div className="grid grid-cols-2 gap-1 px-2 items-baseline">
              <h4 className="text-sm">Gross Fee (USD)</h4>
              <p className="text-xl font-VT323 text-right">{gmsLoading ? <Skeleton/> : USDformat(gms.grossUSDfee)}</p>
              <h4 className="text-sm">Gross Fee (IDR)</h4>
              <p className="text-xl font-VT323 text-right">{gmsLoading ? <Skeleton/> : IDRformat(gms.grossIDRfee)}</p>
              <h4 className="text-sm">Net Fee (USD)</h4>
              <p className="text-xl font-VT323 text-right">{gmsLoading ? <Skeleton/> : USDformat(gms.netUSDfee)}</p>
              <h4 className="text-sm">Net Fee (IDR)</h4>
              <p className="text-xl font-VT323 text-right">{gmsLoading ? <Skeleton/> : IDRformat(gms.netIDRfee)}</p>
            </div>
          </div>

          {/* Costs Card */}
          <div className="flex flex-col h-40 justify-center space-y-10 rounded-lg bg-gradient-to-br from-orange-300/75 to-pink-400 p-2">
            <div className="grid grid-cols-2 gap-2 px-2 items-baseline">
              <h4 className="text-sm">PG Costs</h4>
              <p className="text-3xl font-VT323 text-right">{gmsLoading ? <Skeleton/> : IDRformat(gms.pgCost)}</p>
              <h4 className="text-sm">Network Costs</h4>
              <p className="text-3xl font-VT323 text-right">{gmsLoading ? <Skeleton/> : USDformat(gms.gasFee)}</p>
              <h4 className="text-sm">Infra Costs</h4>
              <p className="text-3xl font-VT323 text-right">{gmsLoading ? <Skeleton/> : USDformat(gms.infraCost)}</p>
            </div>
          </div>

        </div>

        <Divider sx={{mt: 4}} light>
          <h2 className="text-4xl font-VT323">GMS</h2>
        </Divider>

        <div className="mt-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2">
          {/* Users */}
          <div className="flex flex-col h-40 justify-center space-y-10 rounded-lg bg-gradient-to-br from-orange-200/75 to-sky-400 p-2">
            <div className="grid grid-cols-2 gap-2 px-4 items-baseline">
              <h3 className="text-xl font-bold">Total Users</h3>
              <p className="text-3xl font-VT323 text-right">1234</p>
              <h4 className="text-md">GGG</h4>
              <p className="text-2xl font-VT323 text-right">1234</p>
              <h4 className="text-md">SGG</h4>
              <p className="text-2xl font-VT323 text-right">1234</p>
            </div>
          </div>

          {/* Accumulated Fee Card */}
          <div className="flex flex-col h-40 justify-center space-y-10 rounded-lg bg-gradient-to-br from-lime-200/75 to-indigo-400 p-2">
            <div className="grid grid-cols-2 gap-2 px-2 items-baseline">
              <h3 className="text-xl font-bold col-span-2">Accumulated WD Fee</h3>
              <h4 className="text-sm">GGG</h4>
              <p className="text-3xl font-VT323 text-right">123,456 DEP</p>
              <h4 className="text-sm">SGG</h4>
              <p className="text-3xl font-VT323 text-right">123,456 DEP</p>
            </div>
          </div>

          {/* Costs Card */}
          <div className="flex flex-col h-40 justify-center space-y-10 rounded-lg bg-gradient-to-br from-orange-300/75 to-pink-400 p-2">
            <div className="grid grid-cols-2 gap-2 px-2 items-baseline">
              <h3 className="text-xl font-bold col-span-2">Operating Costs</h3>
              <h4 className="text-sm">Network Costs</h4>
              <p className="text-3xl font-VT323 text-right">$123</p>
              <h4 className="text-sm">Infra Costs</h4>
              <p className="text-3xl font-VT323 text-right">$123</p>
            </div>
          </div>
        </div>

      </div>
    </Container>
  )
}

export default BODashboard;