import { css } from '@emotion/react';

import { colors, theme } from '../../shared/styles';

export const styles = {
  wrapper: css`
    position: fixed;
    bottom: 14px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1199;
    & > * {
      background-color: ${colors.neutral} !important;
      border-radius: 16px;
      border-top: transparent;
      box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2),
        0px 16px 24px 2px rgba(0, 0, 0, 0.14),
        0px 6px 30px 5px rgba(0, 0, 0, 0.12);
    }
    max-width: calc(100% - 28px);
    @media screen and (min-width: 768px) {
      bottom: initial;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      & > * {
        display: block !important;
        height: initial !important;
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
        & > a {
          display: block;
          padding: 26px;
          .MuiBottomNavigationAction-label {
            display: none;
          }
        }
      }
    }
  `,
  bottomNavigation: css`
    height: 90px;
    background: #000;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
  `,
  bottomNavigationAction: css`
    color: ${colors.indigo};
    padding: 0 32px;
    & .Mui-selected,
    &.Mui-selected {
      color: ${theme.palette?.yellow?.main};
    }

    & .MuiBottomNavigationAction-label {
      margin-top: 8px;
    }
  `,
};
