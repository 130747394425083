const BASE_URL = import.meta.env.VITE_API_BASE_URL;

export const API = {
  ADMIN_DISBURSEMENT_BUILD: `${BASE_URL}/admin/disbursement/build`,
  ADMIN_DISBURSEMENT_CONFIRM_TXID: `${BASE_URL}/admin/disbursement/txid`,
  ADMIN_DISBURSEMENT_INVOICE: `${BASE_URL}/admin/disbursement/get_invoice`,
  ADMIN_DISBURSEMENT_GET_PENDING: `${BASE_URL}/admin/disbursements/get_pending`,
  ADMIN_DISBURSEMENT_GET_POSTED_DATA: `${BASE_URL}/admin/disbursement/get_posted_data`,
  ADMIN_DISBURSEMENT_MARK_COMPLETE: `${BASE_URL}/admin/disbursement/mark_complete`,
  ADMIN_DISBURSEMENT_TRANSACTION_MARK_COMPLETE: `${BASE_URL}/admin/transactions/mark_complete`,
  ADMIN_DISBURSEMENT_POST: `${BASE_URL}/admin/disbursement/post`,
  ADMIN_DISBURSEMENT_POSTPONE: `${BASE_URL}/admin/disbursement/postpone`,
  ADMIN_DISBURSEMENT_VALIDATE_TOKEN_TRANSFER: `${BASE_URL}/admin/disbursement/validate-token-transfer`,
  ADMIN_DISBURSEMENT_EXECUTE_TOKEN_TRANSFER: `${BASE_URL}/admin/disbursement/execute-token-transfer`,
  ADMIN_DISBURSEMENT_REJECT: `${BASE_URL}/admin/disbursement`,
  ADMIN_DISBURSEMENT_USD_RATE: `${BASE_URL}/admin/disbursement/usdrate`,
  ADMIN_XENDIT_CREATE_BATCH_DISBURSEMENT: `${BASE_URL}/admin/xendit/createBatchDisbursement`,
  DASHBOARD_TRANSACTIONS_STATS: `${BASE_URL}/backoffice/dashboard/bridge-transactions-stats`,
  DASHBOARD_TRADES_STATS: `${BASE_URL}/backoffice/dashboard/bridge-trades-stats`,
  DASHBOARD_GMS_STATS: `${BASE_URL}/backoffice/dashboard/gms-stats`,
  TRANSACTION: `${BASE_URL}/transaction`,
  TRANSACTION_HISTORY: `${BASE_URL}/transaction/history`,
  TRANSACTION_CONFIRM_TXID: `${BASE_URL}/transaction/confirm/txid`,
  NETWORKS: `${BASE_URL}/networks`,
  NETWORKS_TOKEN: `${BASE_URL}/networks/token`,
  USER_PROFILE: `${BASE_URL}/user/profile`,
  VENDOR_DISBURSEMENT: `${BASE_URL}/vendor/disbursement/`,
  VENDOR_DISBURSEMENT_APPROVE: `${BASE_URL}/vendor/disbursement/approve/`,
  VENDOR_DISBURSEMENT_COMPLETED: `${BASE_URL}/vendor/disbursement/completed/`,
  VENDOR_DISBURSEMENT_CREATE: `${BASE_URL}/vendor/disbursement`,
  VENDOR_DISBURSEMENT_MILEAGE: `${BASE_URL}/vendor/mileage/`,
  VENDOR_DISBURSEMENT_NETWORK: `${BASE_URL}/vendor/disbursement/network`,
  VENDOR_DISBURSEMENT_PAY: `${BASE_URL}/vendor/disbursement/pay/`,
  VENDOR_DISBURSEMENT_PAYMENT: `${BASE_URL}/vendor/disbursement/payment/`,
  VENDOR_DISBURSEMENT_PROCESSING: `${BASE_URL}/vendor/disbursement/processing/`,
};
