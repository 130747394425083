import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Snackbar,
  TextField,
} from '@mui/material';

import { QRCodeSVG } from 'qrcode.react';

import Container from '../../components/Container/Container.component';
import ConfirmModal from '../../components/Modals/ConfirmModal.component';
import { API } from '../../shared/api';
import { useFetch, useMutation } from '../../shared/hooks';
import { MutationHttpMethod } from '../../shared/types';

interface IDisbursement {
  currency: string;
  quantity: number;
  total_amount: number;
  invoice_id: {
    id: string;
    address: string;
    network: string | null;
  };
  networks: [{ active: boolean; network: string }];
}

export default function DisbursementPayment() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [data, setData] = useState<IDisbursement>();
  const [network, setNetwork] = useState<string>('');
  const { disbursement_id } = useParams();

  const { isLoading, refetch } = useFetch(
    `disbursement-payment-${disbursement_id}`,
    API.VENDOR_DISBURSEMENT_PAYMENT + disbursement_id,
    {
      options: {
        onSuccess: (res: any) => {
          setData(res.data);
        }
      }
    }
  );

  const cancel = useMutation(API.VENDOR_DISBURSEMENT + disbursement_id, {
    options: {
      onSuccess: () => {
        navigate('/vendor/send');
      }
    }
  }, MutationHttpMethod.PATCH);

  const confirm = useMutation(API.VENDOR_DISBURSEMENT + disbursement_id, {
    options: {
      onSuccess: () => {
        navigate('/vendor/send');
      }
    }
  });

  const networkMut = useMutation(API.VENDOR_DISBURSEMENT_NETWORK, {
    options: {
      onSuccess: () => refetch()
    }
  }, MutationHttpMethod.PATCH)

  async function cancelDisbursement() {
    try {
      setLoadingButton(true);
      await cancel.mutateAsync({});
    } finally {
      setLoadingButton(false);
    }
  }

  async function confirmPayment() {
    try {
      setLoadingButton(true);
      await confirm.mutateAsync({});
    } finally {
      setLoadingButton(false);
    }
  }

  async function setPaymentNetwork() {
    try {
      setLoadingButton(true);
      await networkMut.mutateAsync({ disbursement_id, network });
    } finally {
      setLoadingButton(false);
    }
  }

  function handleSelectChange(event: SelectChangeEvent<string>) {
    const { value } = event.target;
    setNetwork(value);
  }

  function handleCopy(text: string | number) {
    const textString = text.toString();
    navigator.clipboard.writeText(textString);
    setOpenSnackBar(true);
  }

  return (
    <Container>
      <div className="mt-4 mb-12">
        <div>
          <div className="text-white text-2xl md:text-5xl font-VT323">
            {t('Disbursement Payment')}
          </div>
          <div className="text-gray-200 text-sm md:text-md">
            {t('Please pay the total amount of disbursement')}
          </div>
        </div>

        <div className="rounded-md bg-stone-800 p-4 mt-8 mb-8 mx-auto">
          <div className="flex justify-between">
            <div
              className="text-stone-100 md:font-bold text-lg md:text-xl"
              onClick={() => navigate('/vendor/send')}
            >
              {t('← Back')}
            </div>
            <div className="flex flex-col text-right">
              {isLoading ? (
                <>
                  <div className="w-36 my-2 bg-stone-100 h-6 rounded-md animate-pulse"></div>
                  <div className="w-24 my-2 bg-stone-100 h-6 rounded-md animate-pulse"></div>
                </>
              ) : (
                <>
                  <div className="text-lg md:text-2xl text-stone-100">
                    {t('Total: {{quantity}} Recipients', {
                      quantity: data?.quantity ?? 0,
                    })}
                  </div>
                  <div className="text-xl md:text-4xl text-stone-100">
                    {data?.total_amount} {data?.currency}
                  </div>
                </>
              )}
              <div className="mt-4 flex justify-end space-x-2">
                <Button
                  variant="outlined"
                  size="small"
                  color="error"
                  onClick={() => setOpenCancelModal(true)}
                >
                  {t('Cancel Disbursement')}
                </Button>
              </div>
            </div>
          </div>
        </div>

        <div className="rounded-md bg-stone-800 p-4 mt-8 mb-8 mx-auto overflow-auto">
          <div className="flex flex-col justify-center max-w-lg mx-auto p-4">
            {isLoading && (
              <>
                <div className="bg-stone-100 p-4 mx-auto">
                  <div className="w-32 h-32 bg-white animate-pulse"></div>
                </div>
                <div className="mt-10">
                  <div className="w-full my-2 bg-stone-100 h-6 rounded-md animate-pulse"></div>
                </div>
                <div className="mt-10">
                  <div className="w-full my-2 bg-stone-100 h-6 rounded-md animate-pulse"></div>
                </div>
              </>
            )}

            {!isLoading && data?.invoice_id.network && (
              <>
                <div className="bg-stone-100 p-4 mx-auto">
                  <QRCodeSVG value={data?.invoice_id.address ?? ''} />
                </div>
                <div className="mt-10">
                  <TextField
                    label="Payment Address"
                    defaultValue={data?.invoice_id.address}
                    InputProps={{
                      readOnly: true,
                      endAdornment: (
                        <InputAdornment position="end">
                          <ContentCopyIcon
                            onClick={() => handleCopy(data?.invoice_id.address)}
                          />
                        </InputAdornment>
                      ),
                    }}
                    fullWidth
                  />
                </div>
                <div className="mt-6">
                  <div className="grid grid-cols-2 gap-2">
                    <TextField
                      label="Amount"
                      defaultValue={data?.total_amount}
                      InputProps={{
                        readOnly: true,
                        endAdornment: (
                          <InputAdornment position="end">
                            <ContentCopyIcon
                              onClick={() => handleCopy(data?.total_amount)}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <TextField
                      label="Network"
                      defaultValue={data?.invoice_id.network}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </div>
                </div>
                <div className="mt-10 flex justify-center">
                  <Button
                    variant="contained"
                    size="large"
                    disabled={isLoading}
                    onClick={() => setOpenConfirmModal(true)}
                  >
                    {t('I have PAID')}
                  </Button>
                </div>
              </>
            )}
            {!isLoading && !data?.invoice_id.network && (
              <div className="max-w-md flex flex-col">
                <div className="text-xl text-stone-100 text-center">
                  {t('Select Your Payment Network')}
                </div>
                <div className="mt-6 flex space-x-2 mx-auto items-center">
                  <div>
                    <FormControl sx={{ minWidth: 200 }}>
                      <InputLabel id="network-label">
                        {t('Select network')}
                      </InputLabel>
                      <Select
                        labelId="network-label"
                        id="network"
                        value={network}
                        label="Network"
                        name="network"
                        onChange={handleSelectChange}
                      >
                        {data?.networks &&
                          data?.networks.map(item => {
                            return (
                              <MenuItem
                                key={item.network}
                                value={item.network}
                                disabled={!item.active}
                              >
                                {item.network}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </div>

                  <div>
                    <LoadingButton
                      variant="contained"
                      loading={loadingButton}
                      onClick={setPaymentNetwork}
                    >
                      {t('Confirm')}
                    </LoadingButton>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <ConfirmModal
          open={openCancelModal}
          setOpen={setOpenCancelModal}
          loading={loadingButton}
          modalTitle="Are you sure?"
          modalDescription="Do You really want to CANCEL this disbursement?"
          actionHandler={cancelDisbursement}
          actionButton
          actionButtonLabel="Yes, Cancel it!"
          cancelButton
          cancelButtonLabel="No!"
        />
        <ConfirmModal
          open={openConfirmModal}
          setOpen={setOpenConfirmModal}
          loading={loadingButton}
          modalTitle="Are you sure?"
          modalDescription="Do you want to confirm that you have completed the payment transfer for this disbursement?"
          actionHandler={confirmPayment}
          actionButton
          actionButtonLabel="Yes, I have!"
          cancelButton
          cancelButtonLabel="No!"
        />
        <Snackbar
          open={openSnackBar}
          autoHideDuration={4000}
          onClose={() => setOpenSnackBar(false)}
          message="Copied to Clipboard!"
        />
      </div>
    </Container>
  );
}
