import * as React from 'react';

import { LoadingButton } from '@mui/lab';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

interface IConfirmModal {
  open: boolean;
  setOpen: (value: boolean) => void;
  loading?: boolean;
  modalTitle?: string;
  modalDescription?: string;
  actionHandler?: () => void;
  actionButton?: boolean;
  actionButtonLabel?: string;
  cancelButton?: boolean;
  cancelButtonLabel?: string;
}

const ConfirmModal: React.FC<IConfirmModal> = ({
  open,
  setOpen,
  loading,
  modalTitle,
  modalDescription,
  actionHandler,
  actionButton,
  actionButtonLabel,
  cancelButton,
  cancelButtonLabel,
}) => {
  return (
    <div>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <div className="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 w-3/4 md:max-w-md bg-stone-900 rounded-md p-4">
          <Typography
            id="modal-title"
            color="whitesmoke"
            variant="h6"
            component="h2"
          >
            {modalTitle}
          </Typography>
          <Typography
            id="modal-description"
            color="whitesmoke"
            sx={{ mt: 2, mb: 6 }}
          >
            {modalDescription}
          </Typography>
          <Stack justifyContent="end" spacing={2} direction="row">
            {cancelButton && (
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => setOpen(false)}
              >
                {cancelButtonLabel}
              </Button>
            )}
            {actionButton && (
              <LoadingButton
                variant="contained"
                onClick={actionHandler}
                loading={loading ?? false}
                disabled={loading ?? false}
              >
                {actionButtonLabel}
              </LoadingButton>
            )}
          </Stack>
        </div>
      </Modal>
    </div>
  );
};

export default ConfirmModal;
