import { Paper, Divider } from "@mui/material";
import Container from "../../../components/Container/Container.component"

const Balances = () => {

  return (
    <Container>
      <div className="my-4 text-white">
        <Divider light>
          <h2 className="text-4xl font-VT323">Bridge Liquidity</h2>
        </Divider>
        <div className="mt-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2">

          {/* Tokocrypto */}
          <div className="flex flex-col h-40 justify-center space-y-10 rounded-lg bg-gradient-to-br from-orange-200/75 to-sky-400 p-2">
            <div className="grid grid-cols-2 gap-2 px-4 items-baseline">
              <h3 className="text-3xl font-VT323 font-bold col-span-2">Tokocrypto</h3>
              <h4 className="text-md">IDR</h4>
              <p className="text-2xl font-VT323 text-right">Rp1,234,567</p>
              <h4 className="text-md">USDT</h4>
              <p className="text-2xl font-VT323 text-right">1234</p>
            </div>
          </div>

          {/* Xendit */}
          <div className="flex flex-col h-40 justify-center space-y-10 rounded-lg bg-gradient-to-br from-lime-200/75 to-indigo-400 p-2">
            <div className="grid grid-cols-2 gap-2 px-2 items-baseline">
              <h3 className="text-3xl font-VT323 font-bold col-span-2">Xendit</h3>
              <h4 className="text-xl">Ready</h4>
              <p className="text-3xl font-VT323 text-right">Rp1,234,567</p>
              <h4 className="text-xl">Pending</h4>
              <p className="text-3xl font-VT323 text-right">Rp1,234,567</p>
            </div>
          </div>

          {/* BSC */}
          <div className="flex flex-col h-40 justify-center space-y-10 rounded-lg bg-gradient-to-br from-orange-200/75 to-sky-400 p-2">
            <div className="grid grid-cols-2 gap-2 px-4 items-baseline">
              <h3 className="text-3xl font-VT323 font-bold col-span-2">BNB Smart Chain</h3>
              <h4 className="text-xl">BNB</h4>
              <p className="text-3xl font-VT323 text-right">0.21</p>
              <h4 className="text-xl">USDT</h4>
              <p className="text-3xl font-VT323 text-right">1234</p>
            </div>
          </div>

          {/* Polygon */}
          <div className="flex flex-col h-40 justify-center space-y-10 rounded-lg bg-gradient-to-br from-lime-200/75 to-sky-400 p-2">
            <div className="grid grid-cols-2 gap-2 px-2 items-baseline">
              <h3 className="text-3xl font-VT323 font-bold col-span-2">Polygon</h3>
              <h4 className="text-xl">MATIC</h4>
              <p className="text-3xl font-VT323 text-right">12</p>
              <h4 className="text-xl">USDT</h4>
              <p className="text-2xl font-VT323 text-right">1234</p>
            </div>
          </div>

          {/* Ronin */}
          <div className="flex flex-col h-40 justify-center space-y-10 rounded-lg bg-gradient-to-br from-orange-200/75 to-sky-400 p-2">
            <div className="grid grid-cols-2 gap-2 px-4 items-baseline">
              <h3 className="text-3xl font-VT323 font-bold col-span-2">Ronin</h3>
              <h4 className="text-xl">RON</h4>
              <p className="text-3xl font-VT323 text-right">1.21</p>
              <h4 className="text-xl">USDC</h4>
              <p className="text-3xl font-VT323 text-right">1234</p>
            </div>
          </div>

          {/* SOLANA */}
          <div className="flex flex-col h-40 justify-center space-y-10 rounded-lg bg-gradient-to-br from-lime-200/75 to-sky-400 p-2">
            <div className="grid grid-cols-2 gap-2 px-2 items-baseline">
              <h3 className="text-3xl font-VT323 font-bold col-span-2">SOLANA</h3>
              <h4 className="text-xl">SOL</h4>
              <p className="text-3xl font-VT323 text-right">1.23</p>
              <h4 className="text-xl">USDT</h4>
              <p className="text-2xl font-VT323 text-right">1234</p>
            </div>
          </div>

        </div>
      </div>
    </Container>
  )
}

export default Balances;