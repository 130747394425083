import React from 'react';
import { Link } from 'react-router-dom';

import Logout from '@mui/icons-material/Logout';
import {
  Avatar,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
} from '@mui/material';

import logo from '../../assets/images/envoy-white.png';
import { useAuthStore } from '../../core/zustand/store/authStore/authStore';
import { styles } from '../Header/Header.component.styles';
import config, { HandleLoginParams } from './Header.component.config';

const Header: React.FC = function () {
  const { signOut, user } = useAuthStore();

  const isUserLoggedIn = !!user;
  let userNameString;
  if (isUserLoggedIn) {
    userNameString = user.user_metadata.full_name ?? ''
  }

  const handleLogout = async (e: HandleLoginParams) => {
    e.preventDefault();
    try {
      await signOut();
    } catch (error) {
      alert('Unexpected error');
    }
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openAvatarMenu = Boolean(anchorEl);
  const handleClickAvatarMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseAvatarMenu = () => {
    setAnchorEl(null);
  };

  return (
    <div css={styles.header}>
      <Link to="/">
        <img src={logo} alt="envoy portal logo" className="w-24 md:w-32" />
      </Link>
      <div className="flex flex-row">
        {isUserLoggedIn && (
          <Stack spacing={2} direction="row">
            
            <IconButton
              onClick={handleClickAvatarMenu}
              size="small"
              aria-controls={openAvatarMenu ? 'avatar-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={openAvatarMenu ? 'true' : undefined}
            >
              <Avatar
                alt={user.user_metadata.name}
                src={user.user_metadata.avatar_url}
              />
            </IconButton>
            <Menu
              id="avatar-menu"
              anchorEl={anchorEl}
              open={openAvatarMenu}
              onClose={handleCloseAvatarMenu}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              <MenuItem>{userNameString}</MenuItem>
              <MenuItem onClick={handleLogout}>
                <ListItemIcon>
                  <Logout fontSize="small" />
                </ListItemIcon>
                Logout
              </MenuItem>
            </Menu>
          </Stack>
        )}
      </div>
    </div>
  );
};

Header.defaultProps = config.defaultProps;
Header.displayName = config.displayName;
Header.propTypes = config.propTypes;

export default Header;
