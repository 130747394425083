import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { AppBar, Button, CircularProgress, Tab, Tabs } from '@mui/material';

import Container from '../../../components/Container/Container.component';
import { API } from '../../../shared/api';
import { useFetch } from '../../../shared/hooks';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div className="p-3">
          <div className="text-blue-200">{children}</div>
        </div>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

interface IData {
  created_at: string;
  currency: string;
  quantity: number;
  total_amount: number;
  status: number;
  vendor_id: { name: string };
  invoice_id: string;
}

function Disbursements() {
  const { t } = useTranslation();
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const { data, isFetching } = useFetch(
    'pending-vendor-disbursement',
    API.ADMIN_DISBURSEMENT_GET_PENDING
  );

  return (
    <Container>
      <div className="mt-4 mb-12">
        <div>
          <div className="text-white text-2xl md:text-5xl font-VT323">
            {t('Vendor Disbursement Request')}
          </div>
          <div className="text-gray-200 text-sm md:text-md">
            {t('List of disbursements waiting for final confirmation')}
          </div>
        </div>
        <div className="rounded-md bg-stone-800 p-4 mt-8 mb-8 mx-auto">
          <div className="mt-4 flex flex-col divide-y">
            <div>
              <AppBar position="static">
                <Tabs
                  value={tabValue}
                  onChange={handleTabChange}
                  aria-label="basic tabs"
                >
                  <Tab label="Need Confirmation" {...a11yProps(0)} />
                  <Tab label="Confirmed" {...a11yProps(1)} />
                </Tabs>
              </AppBar>
            </div>
            <div>
              {isFetching ? (
                <TabPanel value={tabValue} index={0}>
                  <div className="h-48 mt-4 mb-8 flex justify-center items-center">
                    <CircularProgress />
                  </div>
                </TabPanel>
              ) : (
                <TabPanel value={tabValue} index={0}>
                  {data.pending.length > 0 ? (
                    <div className="mt-4 mb-8 flex flex-col">
                      {data.pending.map((item: IData) => {
                        return (
                          <div
                            key={item.created_at}
                            className="mt-4 mb-8 grid grid-cols-5 gap-2 items-center text-stone-400"
                          >
                            <div className="font-mono">
                              {new Date(item.created_at).toLocaleString()}
                            </div>
                            <div className="col-span-2 text-stone-100">
                              {item.vendor_id.name} {'→'} {item.quantity}{' '}
                              {t('recipients')}
                            </div>
                            <div>
                              {item.total_amount} {item.currency}
                            </div>
                            <div className="flex justify-end">
                              <Button
                                size="small"
                                variant="outlined"
                                component={Link}
                                to={`/portal/vendor/disbursement/${item.invoice_id}`}
                              >
                                {t('Check')}
                              </Button>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <div className="h-48 flex flex-col justify-center p-4 text-center">
                      <div className="text-4xl leading-7 text-white font-VT323">
                        {t('All cleared!')}
                      </div>
                      <div className="mt-3 max-w-sm mx-auto tracking-tight text-stone-200">
                        {t(
                          'After any Vendor confirmed their transfer for their disbursement request, it will show up here.',
                        )}
                      </div>
                    </div>
                  )}
                </TabPanel>
              )}
              {isFetching ? (
                <TabPanel value={tabValue} index={1}>
                  <div className="h-48 mt-4 mb-8 flex justify-center items-center">
                    <CircularProgress />
                  </div>
                </TabPanel>
              ) : (
                <TabPanel value={tabValue} index={1}>
                  {data.completed.length > 0 ? (
                    <div className="mt-4 mb-8 flex flex-col">
                      {data.completed.map((item: IData) => {
                        return (
                          <div
                            key={item.created_at}
                            className="mt-4 mb-8 grid grid-cols-5 gap-2 items-center text-stone-400"
                          >
                            <div className="font-mono">
                              {new Date(item.created_at).toLocaleString()}
                            </div>
                            <div className="col-span-2 text-stone-100">
                              {item.vendor_id.name} {'→'} {item.quantity}{' '}
                              {t('recipients')}
                            </div>
                            <div>
                              {item.total_amount} {item.currency}
                            </div>
                            <div className="flex justify-end">
                              {item.status === 1 ? (
                                <Button
                                  size="small"
                                  variant="outlined"
                                  color="success"
                                >
                                  {t('COMPLETED')}
                                </Button>
                              ) : (
                                <Button
                                  size="small"
                                  variant="outlined"
                                  color="error"
                                >
                                  {t('REJECTED')}
                                </Button>
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <div className="h-48 flex flex-col justify-center text-center">
                      <div className="text-4xl leading-7 text-white font-VT323">
                        {t('Empty')}
                      </div>
                    </div>
                  )}
                </TabPanel>
              )}
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}

export default Disbursements;