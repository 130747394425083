import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import DownloadIcon from '@mui/icons-material/Download';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  SelectChangeEvent,
} from '@mui/material';

import { API } from '../../shared/api';
import { useMutation } from '../../shared/hooks';

const initialInput = {
  currency: 'BUSD',
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function NewDisbursementModal(props: any) {
  const { t } = useTranslation();
  const { open, setOpen, getVendorData } = props;
  const [inputs, setInputs] = useState(initialInput);
  const [file, setFile] = useState<File | null>(null);
  const [loading, setLoading] = useState(false);

  const csvFileToArray = (string: string) => {
    const csvHeader = string.slice(0, string.indexOf('\n')).split(',');
    const csvRows = string.slice(string.indexOf('\n') + 1).split('\n');

    const array = csvRows.map(item => {
      const values = item.split(',');
      const obj = csvHeader.reduce<Record<string, string>>(
        (object, header, index) => {
          object[header.trim()] = values[index].replace(/\r/g, '');
          return object;
        },
        {},
      );
      return obj;
    });

    return array;
  };

  function handleSelectChange(event: SelectChangeEvent<string>) {
    const { name, value } = event.target;
    setInputs(prevState => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  }

  function handleFileChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.files) setFile(event.target.files[0]);
  }

  async function handleCreate() {
    if (!file) return false;
    const fileReader = new FileReader();
    try {
      setLoading(true);
      fileReader.onload = async function (event) {
        const text = event.target?.result as string;
        const payload = text ? csvFileToArray(text) : null;
        if (payload) {
          try {
            await mutation.mutateAsync({ currency: inputs.currency, payload: payload });
          } finally {
            setLoading(false);
          }
        }
      };
      fileReader.readAsText(file);
    } catch (error) {
      console.error(error);
    } 
  }

  const mutation = useMutation(API.VENDOR_DISBURSEMENT_CREATE, {
    options: {
      onSuccess: () => {
        setOpen(false);
        getVendorData();
      }
    }
  })

  return (
    <div>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        sx={{ maxWidth: 'sm', margin: 'auto' }}
      >
        <div className="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 w-11/12 md:w-3/4 bg-stone-900 rounded-md p-4">
          <div className="flex flex-col px-6 text-white">
            <h3 className="my-4 text-xl text-center">
              {t('Create New Disbursement')}
            </h3>
            <div className="relative mt-6">
              <span className="absolute top-0 -left-6 w-6 h-6 rounded-full bg-indigo-400 text-white text-center font-semibold">
                1
              </span>
              <div className="ml-2 flex flex-col space-y-2">
                <div className="text-white font-semibold text-lg">
                  <a
                    className="underline"
                    href="https://docs.google.com/spreadsheets/d/1yc9LdMQNU6l6LnqHtjhltGJvQrSyLTGf86aiWEbkwto/edit?usp=sharing"
                    target="_blank"
                  >
                    {t('Download Template')}
                    <DownloadIcon />
                  </a>{' '}
                  <span className="text-sm text-stone-400">
                    {t('← Click this')}
                  </span>
                </div>
                <p className="text-stone-400 text-sm md:text-base">
                  {t(
                    'Download the .csv template file to be filled with your disbursements data',
                  )}
                </p>
              </div>
            </div>
            <div className="relative mt-6">
              <span className="absolute top-0 -left-6 w-6 h-6 rounded-full bg-indigo-400 text-white text-center font-semibold">
                2
              </span>
              <div className="ml-2 flex flex-col space-y-2">
                <div className="text-white font-semibold text-lg">
                  {t('Upload Filled Template')} <UploadFileIcon />
                </div>
                <p className="text-stone-400 text-sm md:text-base">
                  {t(
                    'After you filled the CSV template, select the file to upload it here.',
                  )}
                </p>
                <Button variant="contained" component="label">
                  {file ? file.name.substring(0, 30) : 'Select .csv File'}
                  <input
                    hidden
                    accept=".csv"
                    type="file"
                    id="csvFileInput"
                    onChange={handleFileChange}
                  />
                </Button>
              </div>
            </div>
            <div className="relative mt-6 mb-4">
              <span className="absolute top-0 -left-6 w-6 h-6 rounded-full bg-indigo-400 text-white text-center font-semibold">
                3
              </span>
              <div className="ml-2 flex flex-col space-y-2">
                <div className="text-white font-semibold text-lg">
                  {t('Define the Currency')} <CurrencyExchangeIcon />
                </div>
                <p className="text-stone-400 text-sm md:text-base">
                  {t(
                    'Every disbursement batch must use same currency, if you want disburse in different currencies, please create separate batches.',
                  )}
                </p>
              </div>
            </div>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id="currency-label">
                {t('Select Currency')}
              </InputLabel>
              <Select
                labelId="currency-label"
                id="currency"
                value={inputs.currency}
                label="Currency"
                name="currency"
                onChange={handleSelectChange}
              >
                <MenuItem value="BUSD">{t('BUSD (Binance USD)')}</MenuItem>
                <MenuItem value="DEP">{t('DEP (DEAPcoin)')}</MenuItem>
                <MenuItem value="SPS" disabled>
                  {t('SPS (Splintershards)')}
                </MenuItem>
                <MenuItem value="DEC" disabled>
                  {t('DEC (DarkEnergyCrystal)')}
                </MenuItem>
                <MenuItem value="AXS" disabled>
                  {t('AXS (Axie Shards)')}
                </MenuItem>
                <MenuItem value="SLP" disabled>
                  {t('SLP (Smooth Love Potion)')}
                </MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="mt-6 flex justify-end space-x-2">
            <Button onClick={() => setOpen(false)} variant="outlined">
              {t('Cancel')}
            </Button>
            <LoadingButton
              loading={loading}
              onClick={handleCreate}
              disabled={!inputs.currency || !file}
              sx={{ mr: 1 }}
              variant="contained"
            >
              {t('Create')}
            </LoadingButton>
          </div>
        </div>
      </Modal>
    </div>
  );
}
