import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { Button } from '@mui/material';

import Container from '../../components/Container/Container.component';
import ConfirmModal from '../../components/Modals/ConfirmModal.component';
import { API } from '../../shared/api';
import { useFetch, useMutation } from '../../shared/hooks';
import { MutationHttpMethod } from '../../shared/types';

interface IDisbursement {
  id: string;
  currency: string;
  payload: [{ ref_id: string; discord_id: string; amount: number }];
  quantity: number;
  total_amount: number;
}

export default function DisbursementApproval() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [openApproveModal, setOpenApproveModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<IDisbursement>();
  const { disbursement_id } = useParams();

  const { isLoading } = useFetch('disbursement-approval-data', API.VENDOR_DISBURSEMENT + disbursement_id, {
    options: {
      enabled: Boolean(disbursement_id),
      onSuccess: (res: any) => {
        setData(res.data);
        setLoading(false);
      }
    }
  })

  async function deleteDisbursementData() {
    try {
      setLoading(true);
      await _delete.mutateAsync({});
    } finally {
      setLoading(false);
    }
  }

  async function approveDisbursementData() {
    try {
      setLoading(true);
      await approve.mutateAsync({});
    } finally {
      setLoading(false);
    }
  }
  const apiUrl = API.VENDOR_DISBURSEMENT + disbursement_id;
  const _delete = useMutation(apiUrl, {
    options: {
      onSuccess: () => {
        navigate('/vendor/send');
      }
    }
  }, MutationHttpMethod.DELETE);

  const approve = useMutation(apiUrl, {
    options: {
      onSuccess: () => {
        navigate(`/vendor/disbursement/pay/${disbursement_id}`);
      }
    }
  }, MutationHttpMethod.PUT)

  return (
    <Container>
      <div className="mt-4 mb-12">
        <div>
          <div className="text-white text-2xl md:text-5xl font-VT323">
            {t('Disbursement Approval')}
          </div>
          <div className="text-gray-200 text-sm md:text-md">
            {t('Are you sure everything is correct?')}
          </div>
        </div>

        <div className="rounded-md bg-stone-800 p-4 mt-8 mb-8 mx-auto">
          <div className="flex justify-between">
            <div
              className="text-stone-100 md:font-bold text-lg md:text-xl"
              onClick={() => navigate('/vendor/send')}
            >
              {t('← Back')}
            </div>
            <div className="flex flex-col justify-end text-right">
              {isLoading ? (
                <>
                  <div className="w-36 my-2 bg-stone-100 h-6 rounded-md animate-pulse"></div>
                  <div className="w-40 my-2 bg-stone-100 h-6 rounded-md animate-pulse"></div>
                </>
              ) : (
                <>
                  <div className="text-lg md:text-2xl text-stone-100">
                    {t('Total: {{quantity}} Recipients', {
                      quantity: data?.quantity ?? 0,
                    })}
                  </div>
                  <div className="text-xl md:text-4xl text-stone-100">
                    {data?.total_amount} {data?.currency}
                  </div>
                </>
              )}
              <div className="mt-4 flex justify-end space-x-2">
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => setOpenConfirmModal(true)}
                >
                  {t('Delete')}
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  onClick={() => setOpenApproveModal(true)}
                >
                  {t('Approve')}
                </Button>
              </div>
            </div>
          </div>
        </div>

        <div className="rounded-md bg-stone-800 p-4 mt-8 mb-8 mx-auto overflow-auto">
          <div className="grid grid-cols-5 gap-2">
            <div className="col-span-2 text-stone-100 font-medium">
              {t('Ref ID')}
            </div>
            <div className="col-span-2 text-stone-100 font-medium">
              {t('Discord_ID')}
            </div>
            <div className="text-stone-100 font-medium text-right">
              {t('Amount')}
            </div>
          </div>
          <hr className="text-purple-100 my-2" />
          {data &&
            data.payload.map(item => {
              return (
                <div className="grid grid-cols-5 gap-4 my-4" key={item.ref_id}>
                  <div className="col-span-2 text-stone-200 text-xs md:text-base">
                    {item.ref_id}
                  </div>
                  <div className="col-span-2 text-stone-200 text-xs md:text-base">
                    {item.discord_id}
                  </div>
                  <div className="text-stone-200 text-xs md:text-base text-right">
                    {item.amount}
                  </div>
                </div>
              );
            })}
        </div>
        <ConfirmModal
          open={openConfirmModal}
          setOpen={setOpenConfirmModal}
          loading={loading}
          modalTitle="Are you sure?"
          modalDescription="Please confirm if you really want to DELETE this disbursement"
          actionHandler={deleteDisbursementData}
          actionButton
          actionButtonLabel="DELETE"
          cancelButton
          cancelButtonLabel="No!"
        />
        <ConfirmModal
          open={openApproveModal}
          setOpen={setOpenApproveModal}
          loading={loading}
          modalTitle="Are you sure?"
          modalDescription="Do you really want to APPROVE this disbursement? An Invoice will be generated after this!"
          actionHandler={approveDisbursementData}
          actionButton
          actionButtonLabel="APPROVE"
          cancelButton
          cancelButtonLabel="No!"
        />
      </div>
    </Container>
  );
}
