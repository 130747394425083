import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import SearchIcon from '@mui/icons-material/Search';

import logo from '../../assets/images/envoyportal_logo.png';
import Container from '../../components/Container/Container.component';
import supabaseClient from '../../core/services/supabase/supabase.service';
import { useAuthStore } from '../../core/zustand/store/authStore/authStore';
import { API } from '../../shared/api';
import { useFetch } from '../../shared/hooks';

type Vendor = {
  id: number;
  name: string;
  code: string;
  total_stable_txs: number;
  total_token_txs: number;
};

interface IData {
  id: string;
  currency: string;
  payload: [ref_id: string, discord_id: string, amount: string];
  quantity: number;
  total_amount: number;
}

interface ITxData {
  total_txs: number;
  hours_saved: string;
  gasfee_saved: string;
}

function Dashboard() {
  const { t } = useTranslation();
  const { user } = useAuthStore();
  const [vendor, setVendor] = useState<Vendor>();
  const [payList, setPayList] = useState<IData[]>([]);
  const [completedList, setCompletedList] = useState<IData[]>([]);
  const [txData, setTxData] = useState<ITxData>({
    total_txs: 0,
    hours_saved: '0',
    gasfee_saved: '0',
  });

  const navigate = useNavigate();

  const getVendorName = async () => {
    const { data, error } = await supabaseClient
      .from('profiles')
      .select('vendor_id(id, name, code, total_stable_txs, total_token_txs)')
      .single();
    if (error) alert(`Unexpected Error: ${error.message}`);
    else {
      const vendorData = data as any
      const vendor = vendorData.vendor_id as Vendor
      setVendor(vendor)
    }
  }

  useEffect(() => {
    getVendorName(); 
  }, [user]);

  const { isFetching: payListFetching } = useFetch('paylist', API.VENDOR_DISBURSEMENT_PAY + vendor?.id, {
    options: {
      enabled: Boolean(vendor?.id),
      onSuccess: (res: any) => {
        if (res.success) setPayList(res.data);
      }
    }
  })

  const { isFetching: completedListFetching } = useFetch('completed-list', API.VENDOR_DISBURSEMENT_COMPLETED + vendor?.id, {
    options: {
      enabled: Boolean(vendor?.id),
      onSuccess: (res: any) => {
        if (res.success) setCompletedList(res.data);
      }
    }
  })

  const { isFetching: mileageFetching } = useFetch('mileage', API.VENDOR_DISBURSEMENT_MILEAGE + vendor?.code, {
    options: {
      enabled: Boolean(vendor?.code),
      onSuccess: (res: any) => {
        if (res.success) setTxData(res.data);
      }
    }
  })

  return (
    <Container>
      <div className="mt-4 mb-12">
        <div>
          <div className="text-white text-2xl md:text-5xl font-VT323">
            {t('Hello, ')}
            {vendor?.name ?? 'Vendor'}
          </div>
          <div className="text-gray-200 text-sm md:text-md">
            {t('Welcome back!')}
          </div>
        </div>
        <div className="mt-6 grid grid-cols-1 gap-10 md:gap-3 md:grid-cols-3">
          {/* Feature Card */}
          <div className="flex flex-col h-40 justify-between rounded-lg bg-gradient-to-br from-pink-100/75 to-purple-500 p-2">
            <div>
              <div className="font-light text-center text-black">
                {t('Mass disbursements is easy with')}
              </div>
              <img
                src={logo}
                className="my-2 w-28 mx-auto"
                alt="ENVOY Portal Logo"
              />
              <div className="font-medium tracking-tight text-sm md:tracking-tighter text-center text-black">
                {t('No manual transfers. No excessive Gas Fee.')}
              </div>
              <div className="font-medium tracking-tight text-sm text-center text-black">
                {t('Just upload your list → Pay → Done!')}
                <span role="img" aria-label="confetti">
                  {'🎉'}
                </span>
              </div>
            </div>
            <div className="leading-relaxed text-right text-stone-900">
              <div className="mt-4 mb-4 flex justify-end">
                <div
                  className="px-2 py-1 rounded-lg bg-gradient-to-tr from-sky-500 hover:from-sky-300 to-lime-300 hover:to-lime-200 font-medium text-black cursor-pointer"
                  onClick={() => navigate('/vendor/send')}
                >
                  {t('New Disbursement')}
                </div>
              </div>
            </div>
          </div>
          {/* Pending Transaction */}
          <div className="relative flex flex-col h-40 justify-between space-y-10 rounded-lg bg-gradient-to-br from-orange-200/75 to-sky-400 p-2">
            <div className="text-sm font-bold text-black">
              {t('On-going Transaction')}
            </div>
            {payListFetching ? (
              <div className="flex flex-col space-y-2 items-end">
                <div className="w-3/4 h-8 bg-stone-500 rounded-xl animate-pulse"></div>
                <div className="w-1/2 h-6 bg-stone-500 rounded-xl animate-pulse"></div>
              </div>
            ) : (
              <div className="leading-relaxed text-right text-stone-900">
                {payList.length > 0 ? (
                  <>
                    <div className="text-2xl md:text-xl tracking-tighter">
                      {payList[0].total_amount} {payList[0].currency} {t('to')}{' '}
                      {payList[0].quantity} {t('recipients')}
                    </div>
                    <div className="font-light">{t('Waiting for Payment')}</div>
                    <div className="mt-3 mb-4 flex justify-end">
                      <div
                        className="absolute -bottom-3 right-2 px-2 py-1 rounded-lg bg-gradient-to-tr from-orange-300 to-green-300 font-medium text-black cursor-pointer"
                        onClick={() =>
                          navigate(`/vendor/disbursement/pay/${payList[0].id}`)
                        }
                      >
                        {t('Proceed to Pay')}
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="text-2xl tracking-tighter">
                      {t('No On-going Transaction')}
                    </div>
                    <div className="font-light">{t('All clear for now')}</div>
                    <div className="mt-3 mb-4 flex justify-end">
                      <div className="px-2 py-1 rounded-lg bg-stone-600 font-medium text-gray-800 cursor-not-allowed">
                        {t('Proceed to Pay')}
                      </div>
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
          {/* Time & Fee saving card */}
          <div className="grid grid-cols-2 h-40 rounded-lg bg-gradient-to-br from-indigo-400 to-lime-300 p-2">
            <div className="text-sm tracking-tighter text-black">
              {t('Your Mileage so far...')}
            </div>
            <div className="flex flex-col justify-center text-center">
              {mileageFetching ? (
                <div className="w-1/2 h-10 bg-stone-500 rounded-xl animate-pulse mx-auto"></div>
              ) : (
                <div className="text-4xl font-VT323 text-black">
                  {txData.total_txs}
                </div>
              )}
              <div className="tracking-tighter text-xs text-black">
                {t('Transactions')}
              </div>
            </div>
            <div className="flex flex-col justify-center text-center">
              {mileageFetching ? (
                <div className="w-1/2 h-10 bg-stone-500 rounded-xl animate-pulse mx-auto"></div>
              ) : (
                <div className="text-4xl font-VT323 text-black">
                  {txData.hours_saved}
                </div>
              )}
              <div className="tracking-tighter text-xs text-black">
                {t('Hours Saved')}
              </div>
            </div>
            <div className="flex flex-col justify-center text-center">
              {mileageFetching ? (
                <div className="w-1/2 h-10 bg-stone-500 rounded-xl animate-pulse mx-auto"></div>
              ) : (
                <div className="text-4xl font-VT323 text-black">
                  ${txData.gasfee_saved}
                </div>
              )}
              <div className="tracking-tighter text-xs text-black">
                {t('Gas Fee Saved')}
              </div>
            </div>
          </div>
          {/* Latest Transactions card */}
          <div className="md:mt-8 md:col-span-2 rounded-lg bg-stone-600 p-2">
            <div className="grid grid-cols-3 gap-2">
              <div className="font-medium md:text-lg text-purple-400 col-span-2">
                {t('Latest Transactions')}
              </div>
              <div className="flex justify-end cursor-pointer">
                <SearchIcon
                  color="primary"
                  onClick={() => navigate('/vendor/history')}
                />
                <div className="text-purple-400">{t('more...')}</div>
              </div>
            </div>
            {!completedListFetching &&
              completedList.length > 0 &&
              completedList.slice(0, 5).map(item => {
                return (
                  <div key={item.id} className="grid grid-cols-3 gap-2 my-2">
                    <div className="col-span-2 text-sm md:text-base text-stone-100">
                      {t('Disbursements to {{quantity}} recipients', {
                        quantity: item.quantity,
                      })}
                    </div>
                    <div className="text-sm md:text-base text-stone-100 text-right">
                      {item.total_amount} {item.currency}
                    </div>
                  </div>
                );
              })}
            {completedListFetching && (
              <div className="grid grid-cols-3 gap-2 my-2">
                <div className="w-full h-8 col-span-2 bg-stone-500 rounded-xl animate-pulse"></div>
                <div className="w-full h-8 bg-stone-500 rounded-xl animate-pulse"></div>
                <div className="w-full h-8 col-span-2 bg-stone-500 rounded-xl animate-pulse"></div>
                <div className="w-full h-8 bg-stone-500 rounded-xl animate-pulse"></div>
                <div className="w-full h-8 col-span-2 bg-stone-500 rounded-xl animate-pulse"></div>
                <div className="w-full h-8 bg-stone-500 rounded-xl animate-pulse"></div>
              </div>
            )}
            {!completedListFetching && !completedList.length && (
              <div className="col-span-3 text-lg font-semibold text-center text-stone-300">
                {t('No Transaction data, yet.')}
              </div>
            )}
          </div>
        </div>
      </div>
    </Container>
  );
}

export default Dashboard;
