import { Box, Button, TextField } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Link } from 'react-router-dom';

import Container from '../../components/Container/Container.component';
import { useAuthStore } from '../../core/zustand/store/authStore/authStore';
import { useState, useEffect } from 'react';

const Home = function () {
  const { user, signInWithEmail, signInWithDiscord } = useAuthStore();
  const [email, setEmail] = useState("")
  const [otpSent, setOtpSent] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const handleEmailLogin = async () => {
    setIsLoading(true)
    const { error } = await signInWithEmail(email)
    if (!error) {
      setOtpSent(true)
      setIsLoading(false)
    } else {
      setIsLoading(false)
      throw error.message
    }
  }

  const handleChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value)
  }

  const handleDiscordLogin = async () => {
    await signInWithDiscord();
  }

  return (
    <div>
      <Container>
        <div>
          {user ? (
            <div className="text-white">
              <h2 className="text-4xl font-bold">
                Hello,{' '}
                <Box color="#F19C1D" component="span">
                  <span>{user?.user_metadata.full_name}</span>
                </Box>
              </h2>
              {user.role && (
                  <div className="grid grid-cols-1 mt-8 sm:grid-cols-2 md:grid-cols-3 gap-5">
                  
                  {['admin', 'superadmin'].includes(user.role) && (
                    <div
                      style={{
                        background:
                          'linear-gradient(206.05deg, rgba(12, 69, 247, 0.5) 8.71%, rgba(18, 8, 43, 0) 90.67%)',
                      }}
                      className="rounded-xl p-6"
                    >
                      <Link to="/backoffice/dashboard">
                        <div className="relative text-white">
                          <div className="text-4xl font-VT323">Admin</div>
                          <div className="text-2xl">Dashboard</div>
                          <p className="text-xs mt-2">
                            Manage all Back-office activities and data
                          </p>
                          <ArrowForwardIosIcon className="clickable-arrow" />
                        </div>
                      </Link>
                    </div>
                  )}

                  {['admin', 'superadmin'].includes(user.role) && (
                    <div
                      style={{
                        background:
                        'linear-gradient(206.05deg, #E6CC40 8.71%, rgba(18, 8, 43, 0.5) 90.67%)',
                      }}
                      className="rounded-xl p-6"
                    >
                      <Link to="/portal/vendor/disbursements">
                        <div className="relative text-white">
                          <div className="text-4xl font-VT323">Admin</div>
                          <div className="text-2xl">Disbursements</div>
                          <p className="text-xs mt-2">
                            Manage all Vendor's Disbursements Requests
                          </p>
                          <ArrowForwardIosIcon className="clickable-arrow" />
                        </div>
                      </Link>
                    </div>
                  )}

                  {['vendor', 'admin', 'superadmin'].includes(user.role) && (
                    <div
                      style={{
                        background:
                          'linear-gradient(206.05deg, #E6CC40 8.71%, rgba(18, 8, 43, 0.5) 90.67%)',
                      }}
                      className="rounded-xl p-6"
                    >
                      <Link to="/vendor/dashboard">
                        <div className="relative text-white">
                          <div className="text-4xl font-VT323">Vendor</div>
                          <div className="text-2xl">Dashboard Access</div>
                          <p className="text-xs mt-2">
                            Manage all Vendor activities
                          </p>
                          <ArrowForwardIosIcon className="clickable-arrow" />
                        </div>
                      </Link>
                    </div>
                  )}
                </div>
                )}
            </div>
          ) : (
            <div className="max-w-[400px] mx-auto w-full p-8 rounded-lg" style={{
              background: 'linear-gradient(206.05deg, rgba(12, 69, 247, 0.5) 8.71%, rgb(70, 24, 150) 90.67%)',
            }}>
              <form action="">
                <div className="text-center">
                  <h3 className="text-2xl font-bold text-gray-200">Back Office Access</h3>
                </div>
                {!otpSent ? (
                  <div className="space-y-4 mt-8">
                  <div>
                    <TextField
                      fullWidth
                      id="filled-basic"
                      label="Email"
                      variant="filled"
                      value={email}
                      onChange={handleChangeEmail}
                    />
                  </div>
                  <div>
                    <Button
                      fullWidth
                      onClick={handleEmailLogin}
                      disabled={isLoading}
                      variant="contained"
                    >
                      {isLoading ? "Loading..." : "Login"}
                    </Button>
                  </div>
                  <div
                    className="border-t border-gray-300 text-center relative pb-3"
                    style={{ marginTop: 30 }}
                  >
                    <span className="text-xs text-gray-500 absolute left-1/2 bg-white block -top-[8px] px-2 transform -translate-x-1/2">
                      OR
                    </span>
                  </div>
                  <div>
                    <Button fullWidth variant="contained" color="info" onClick={handleDiscordLogin}>
                      Login With Discord
                    </Button>
                  </div>
                  <div className="text-center text-sm">
                    <span className="text-gray-500">
                      Need help? <b className="text-indigo-600">Contact Us</b>
                    </span>
                  </div>
                </div>
                ) : (
                  <div className="text-lg text-gray-300 mt-4">
                    <p><b>OTP Link </b>has been sent to your email. Click the link to login to your account.</p>
                  </div>
                )}
              </form>
            </div>
          )}
        </div>
      </Container>
    </div>
  );
};

export default Home;
