import { useEffect, useMemo, useRef, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  QueryFunction,
  QueryKey,
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
  UseQueryOptions,
  UseQueryResult,
  useMutation as useMut,
  useQuery,
} from '@tanstack/react-query';

import qs from 'qs';

import { getToken } from './constants';
import { MutationHttpMethod, ParamsType } from './types';

export const usePaths = () => {
  const { search, pathname } = useLocation();
  const navigate = useNavigate();
  const params = useMemo(() => {
    return qs.parse(search, { ignoreQueryPrefix: true });
  }, [search]);

  const onBack = () => {
    const keys = Object.keys(params);
    if (params.ref) {
      navigate(params.ref as string, { replace: true });
    } else {
      if (keys[keys.length - 1] === 'q') {
        delete params[keys[keys.length - 1]];
        navigate(`${pathname}`, { replace: true });
      } else {
        delete params[keys[keys.length - 1]];
        navigate(`${pathname}?${qs.stringify(params)}`, { replace: true });
      }
    }
  };

  return { onBack, params, pathname, navigate, search };
};

export const useCopyToClipboard = () => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isCopied, setCopied] = useState(false);

  const onCopy = () => {
    inputRef.current && inputRef.current.select();
    inputRef.current &&
      inputRef.current.setSelectionRange(0, 99999); /* For mobile devices */

    navigator.clipboard.writeText(
      (inputRef.current && inputRef.current.value) || '',
    );
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1000);
  };

  return {
    inputRef,
    isCopied,
    onCopy,
  };
};

export const useFetch = (key: string, path: string, params?: ParamsType) => {
  const accessToken = getToken();
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };
  const fetchOptions = {
    ...params?.init,
    headers: headers,
  };
  return useQuery(
    [key, params?.variables],
    async () => {
      return fetch(path, fetchOptions)
        .then(async (response) => {
          if (!response.ok) {
            throw new Error(response.statusText);
          } else {
            try {
              return await response.json()
            } catch (error) {
              return response.body;
            }
          }
        });
    },
    {
      retry: 3,
      retryDelay: 500,
      onError: (err: Error) => {
        toast.error(`Error in fetching data: ${err.message}`);
      },
      ...params?.options,
    },
  );
};

export const useMutation = <TData>(
  path: string,
  params?: ParamsType,
  method: MutationHttpMethod = MutationHttpMethod.POST,
) => {
  const accessToken = getToken();
  return useMut(
    (formData: TData) => {
      return fetch(path, {
        method,
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
        ...params?.init,
      })
      .then(async (response) => {
        if (!response.ok) {
          throw new Error(response.statusText);
        } else {
          try {
            return await response.json()
          } catch (error) {
            return response.body;
          }
        }
      });
    },
    {
      onError: (err: Error) => {
        toast.error(`Error in mutating data: ${err.message}`);
      },
      ...params?.options,
    },
  );
};

export const useDebounce = <T>(value: T, delay?: number): T => {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay || 500);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return debouncedValue;
};

type QueryRefetchType = <TPageData>(
  options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined,
) => Promise<QueryObserverResult<unknown, unknown>>;
export const useLazyQuery = <
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(
  key: TQueryKey,
  fn: QueryFunction<TQueryFnData, QueryKey>,
  options: Omit<
    UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
    'queryKey' | 'queryFn' | 'initialData'
  > & {
    initialData?: () => undefined;
  } = {},
): readonly [QueryRefetchType, UseQueryResult<unknown, unknown>] => {
  const query = useQuery<TQueryFnData, TError, TData, TQueryKey>(key, fn, {
    ...options,
    enabled: false,
  });

  return [query.refetch, query];
};

export const useRetryTransaction = (
  transaction: any,
  refetch: () => void,
  options: {
    enabled: boolean;
  } = { enabled: true },
) => {
  const retryRef = useRef<any>(null);

  const onClearInterval = () => {
    clearInterval(retryRef.current);
  };

  useEffect(() => {
    if (transaction && transaction?.status !== 2 && options?.enabled) {
      retryRef.current = setInterval(() => {
        refetch();
      }, 6000);
    } else {
      if (retryRef.current) {
        onClearInterval();
      }
    }

    return () => {
      onClearInterval();
    };
  });

  return { onClearInterval, retryRef };
};
