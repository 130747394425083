import { create } from 'zustand';

import supabaseClient from '../../../services/supabase/supabase.service';
import { IAuthStore } from './authStore.config';

export const useAuthStore = create<IAuthStore>(set => ({
  user: null,
  accessToken: null,
  signInWithEmail: (email: string) => supabaseClient.auth.signInWithOtp({
    email: email,
    options: {
      emailRedirectTo: location.origin,
    }
  }),
  signInWithDiscord: () => supabaseClient.auth.signInWithOAuth({
    provider: 'discord',
    options: {
      redirectTo: location.origin
    }
  }),
  signOut: () => supabaseClient.auth.signOut(),
  setUser: user => {
    set(_state => ({
      user,
    }));
  },
  setAccessToken: accessToken => {
    set(_state => ({
      accessToken,
    }));
  },
  refreshSession: () => supabaseClient.auth.refreshSession(),
}));
