import { Navigate, Outlet, Route, Routes } from 'react-router-dom';

import { useAuthStore } from '../core/zustand/store/authStore/authStore';
import DisbursementInvoiceApproval from '../screens/Admin/Vendor/DisbursementInvoiceApproval.screen';
import Disbursements from '../screens/Admin/Vendor/Disbursements.screen';
import Home from '../screens/Home/Home.screen';
import Dashboard from '../screens/Vendor/Dashboard.screen';
import BODashboard from '../screens/Admin/BackOffice/Dashboard.screen';
import Balances from '../screens/Admin/BackOffice/Balances.screen';
import DisbursementApproval from '../screens/Vendor/DisbursementApproval.screen';
import DisbursementPayment from '../screens/Vendor/DisbursementPayment.screen';
import History from '../screens/Vendor/History.screen';
import Send from '../screens/Vendor/Send.screen';

const ProtectedRoute = ({
  isAllowed,
  redirectPath = '/',
  children,
}: {
  isAllowed: boolean;
  redirectPath?: string;
  children?: JSX.Element;
}) => {
  if (!isAllowed) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ? children : <Outlet />;
};

const Navigation = function () {
  const { user } = useAuthStore();

  return (
    <Routes>
      <Route index element={<Home />} />
      <Route
        element={
          <ProtectedRoute isAllowed={!!user && ['vendor', 'admin', 'superadmin'].includes(user.role!)} />
        }
      >
        <Route path="vendor/dashboard" element={<Dashboard />} />
        <Route path="vendor/send" element={<Send />} />
        <Route path="vendor/history" element={<History />} />
        <Route
          path="vendor/disbursement/:disbursement_id"
          element={<DisbursementApproval />}
        />
        <Route
          path="vendor/disbursement/pay/:disbursement_id"
          element={<DisbursementPayment />}
        />
      </Route>

      <Route
        element={
          <ProtectedRoute isAllowed={!!user && ['admin', 'superadmin'].includes(user.role!)} />
        }
      >
        <Route path="portal/vendor/disbursements" element={<Disbursements />} />
        <Route
          path="portal/vendor/disbursement/:invoice_id"
          element={<DisbursementInvoiceApproval />}
        />
        <Route path="backoffice/dashboard" element={<BODashboard />} />
        <Route path="backoffice/balances" element={<Balances />} />
      </Route>
    </Routes>
  );
};

export default Navigation;
