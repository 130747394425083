import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import {
  AppBar,
  Badge,
  Button,
  CircularProgress,
  Tab,
  Tabs,
} from '@mui/material';

import Container from '../../components/Container/Container.component';
import NewDisbursementModal from '../../components/Modals/CreateNewDisbursementModal.component';
import supabaseClient from '../../core/services/supabase/supabase.service';
import { useAuthStore } from '../../core/zustand/store/authStore/authStore';
import { API } from '../../shared/api';
import { useFetch } from '../../shared/hooks';
import { toast } from 'react-hot-toast';

type Vendor = {
  id: number;
  name: string;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div className="p-3">
          <div className="text-blue-200">{children}</div>
        </div>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

interface IData {
  id: string;
  currency: string;
  payload: [ref_id: string, discord_id: string, amount: string];
  quantity: number;
  total_amount: number;
}

function Send() {
  const { t } = useTranslation();
  const { user } = useAuthStore();
  const [vendor, setVendor] = useState<Vendor>();
  const [tabValue, setTabValue] = useState(0);

  const [openModal, setOpenModal] = useState(false);

  const [approveList, setApproveList] = useState<IData[]>([]);
  const [payList, setPayList] = useState<IData[]>([]);
  const [processingList, setProcessingList] = useState<IData[]>([]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const getVendorName = async () => {
    const { data, error } = await supabaseClient
      .from('profiles')
      .select('vendor_id(id, name)')
      .single();
    if (error) toast.error(error.message);
    else {
      const vendorData = data as any
      const vendor = vendorData.vendor_id as Vendor
      setVendor(vendor)
    }
  }

  useEffect(() => {
    getVendorName(); 
  }, [user]);

  const { isFetching: approveListFetching, refetch } = useFetch('approve-list', API.VENDOR_DISBURSEMENT_APPROVE + vendor?.id, {
    options: {
      enabled: Boolean(vendor?.id),
      onSuccess: (res: any) => {
        if (res.success) setApproveList(res.data);
      }
    }
  })

  const { isFetching: payListFetching } = useFetch('pay-list', API.VENDOR_DISBURSEMENT_PAY + vendor?.id, {
    options: {
      enabled: Boolean(vendor?.id),
      onSuccess: (res: any) => {
        if (res.success) setPayList(res.data);
      }
    }
  })

  const { isFetching: processingListFetching } = useFetch('processing-list', API.VENDOR_DISBURSEMENT_PROCESSING + vendor?.id, {
    options: {
      enabled: Boolean(vendor?.id),
      onSuccess: (res: any) => {
        if (res.success) setProcessingList(res.data);
      }
    }
  })

  return (
    <Container>
      <div className="mt-4 mb-12">
        <div>
          <div className="text-white text-2xl md:text-5xl font-VT323">
            {t('Hello, ')}
            {vendor?.name ?? 'Vendor'}
          </div>
          <div className="text-gray-200 text-sm md:text-md">
            {t('Ready to create new disbursement?')}
          </div>
        </div>
        <div className="rounded-md bg-stone-800 p-4 mt-8 mb-8 mx-auto">
          <div className="flex justify-end">
            <Button onClick={() => setOpenModal(true)} variant="contained">
              {t('Create')}
            </Button>
          </div>
          <div className="mt-4 flex flex-col divide-y">
            <div>
              <AppBar position="static">
                <Tabs
                  value={tabValue}
                  onChange={handleTabChange}
                  aria-label="basic tabs"
                  variant="fullWidth"
                >
                  <Tab label="Need Approval" {...a11yProps(0)} />
                  <Tab
                    label={
                      <Badge
                        badgeContent={payList.length}
                        color="error"
                        sx={{ px: 1 }}
                      >
                        {t('Need Payment')}
                      </Badge>
                    }
                    {...a11yProps(1)}
                  />
                  <Tab
                    label={
                      <Badge
                        badgeContent={processingList.length}
                        color="error"
                        sx={{ px: 1 }}
                      >
                        {t('Processing')}
                      </Badge>
                    }
                    {...a11yProps(1)}
                  />
                </Tabs>
              </AppBar>
            </div>
            <div>
              {approveListFetching ? (
                <TabPanel value={tabValue} index={0}>
                  <div className="h-48 mt-4 mb-8 flex justify-center items-center">
                    <CircularProgress />
                  </div>
                </TabPanel>
              ) : (
                <TabPanel value={tabValue} index={0}>
                  {approveList.length > 0 ? (
                    <div className="mt-4 mb-8 flex flex-col">
                      {approveList.map(item => {
                        return (
                          <div
                            key={item.id}
                            className="mt-4 mb-8 grid grid-cols-5 gap-2 items-center text-stone-200"
                          >
                            <div className="font-mono">
                              {item.id.substring(0, 5)}
                            </div>
                            <div className="col-span-2 text-stone-100">
                              {'→'} {item.quantity} {t('recipients')}
                            </div>
                            <div>
                              {item.total_amount} {item.currency}
                            </div>
                            <div className="flex justify-end">
                              <Button
                                size="small"
                                variant="outlined"
                                component={Link}
                                to={`/vendor/disbursement/${item.id}`}
                              >
                                {t('Check')}
                              </Button>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <div className="h-48 flex flex-col justify-center p-4 text-center">
                      <div className="text-4xl leading-7 text-white font-VT323">
                        {t('Nothing here :)')}
                      </div>
                      <div className="mt-3 max-w-sm mx-auto tracking-tight text-stone-200">
                        {t(
                          'You can create new Disbursement Batch, your uploaded data will be shown here for validation',
                        )}
                      </div>
                    </div>
                  )}
                </TabPanel>
              )}
              {payListFetching ? (
                <TabPanel value={tabValue} index={1}>
                  <div className="h-48 mt-4 mb-8 flex justify-center items-center">
                    <CircularProgress />
                  </div>
                </TabPanel>
              ) : (
                <TabPanel value={tabValue} index={1}>
                  {payList.length > 0 ? (
                    <div className="mt-4 mb-8 flex flex-col">
                      {payList.map(item => {
                        return (
                          <div
                            key={item.id}
                            className="mt-4 mb-8 grid grid-cols-5 gap-2 items-center text-stone-200"
                          >
                            <div className="font-mono">
                              {item.id.substring(0, 5)}
                            </div>
                            <div className="col-span-2 text-stone-100">
                              {'→'} {item.quantity} {t('recipients')}
                            </div>
                            <div>
                              {item.total_amount} {item.currency}
                            </div>
                            <div className="flex justify-end">
                              <Button
                                size="small"
                                variant="outlined"
                                component={Link}
                                to={`/vendor/disbursement/pay/${item.id}`}
                              >
                                {t('Check')}
                              </Button>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <div className="h-48 flex flex-col justify-center text-center">
                      <div className="text-4xl leading-7 text-white font-VT323">
                        {t('All caught up! :)')}
                      </div>
                      <div className="mt-3 max-w-sm mx-auto tracking-tight text-stone-200">
                        {t(
                          "There's no unpaid on-going transaction for now. If you created new Disbursement batch, it will show up here for payment after you confirmd and validated it.",
                        )}
                      </div>
                    </div>
                  )}
                </TabPanel>
              )}
              {processingListFetching ? (
                <TabPanel value={tabValue} index={2}>
                  <div className="h-48 mt-4 mb-8 flex justify-center items-center">
                    <CircularProgress />
                  </div>
                </TabPanel>
              ) : (
                <TabPanel value={tabValue} index={2}>
                  {processingList.length > 0 ? (
                    <div className="mt-4 mb-8 flex flex-col">
                      {processingList.map(item => {
                        return (
                          <div
                            key={item.id}
                            className="mt-4 mb-8 grid grid-cols-5 gap-2 items-center text-stone-200"
                          >
                            <div className="font-mono">
                              {item.id.substring(0, 5)}
                            </div>
                            <div className="col-span-2 text-stone-100">
                              {'→'} {item.quantity} {t('recipients')}
                            </div>
                            <div>
                              {item.total_amount} {item.currency}
                            </div>
                            <div className="flex justify-end">
                              <CircularProgress size={25} />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <div className="h-48 flex flex-col justify-center text-center">
                      <div className="text-4xl leading-7 text-white font-VT323">
                        {t('All caught up! :)')}
                      </div>
                      <div className="mt-3 max-w-sm mx-auto tracking-tight text-stone-200">
                        {t(
                          "There's no Processing transaction for now. If you just paid a disbursement, it will show up here until it completely disbursed.",
                        )}
                      </div>
                    </div>
                  )}
                </TabPanel>
              )}
            </div>
          </div>
        </div>
        <NewDisbursementModal
          open={openModal}
          setOpen={setOpenModal}
          getVendorData={refetch}
        />
      </div>
    </Container>
  );
}

export default Send;
