import { Box } from '@mui/material';

import Footer from '../Footer/Footer.component';
import Header from '../Header/Header.component';
import config, { IContainer } from './Container.component.config';
import { styles } from './Container.component.styles';

const Container: React.FC<IContainer> = ({
  children,
  withHeader,
  withFooter,
}) => {

  return (
    <div css={styles.bg} className="min-h-screen">
      {withHeader && <Header />}
      <Box
        sx={{
          '@media screen and (min-width: 768px) and (max-width: 1350px)': {
            paddingLeft: '104px',
          },
        }}
        className="pt-4 pb-12 max-w-5xl mx-auto px-6"
      >
        <div className="mt-6 pb-32">{children}</div>
        {withFooter && <Footer />}
      </Box>
    </div>
  );
};

Container.defaultProps = config.defaultProps;
Container.displayName = config.displayName;
Container.propTypes = config.propTypes;

export default Container;
