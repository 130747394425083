export type HandleLoginParams = {
  preventDefault: () => void;
};

const propTypes = {};

const defaultProps = {};

const displayName = 'Header';

export default { propTypes, defaultProps, displayName };
