import { ReactElement } from 'react';

import PropTypes from 'prop-types';

export interface IContainer {
  children: ReactElement;
  withHeader?: boolean;
  withFooter?: boolean;
}

const propTypes = {
  children: PropTypes.element.isRequired,
  withHeader: PropTypes.bool,
  withFooter: PropTypes.bool,
};

const defaultProps = {
  withHeader: true,
  withFooter: true,
};

const displayName = 'Container';

export default { propTypes, defaultProps, displayName };
