import { css } from '@emotion/react';

export const styles = {
  bg: css`
    content: ' ';
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    z-index: 0;
  `,
  wrapper: css`
    width: 100%;
    min-height: 100vh;
    padding-top: 30px;
    padding-bottom: 90px;
    display: flex;
    align-items: center;
  `,
};
