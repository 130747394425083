import { css } from '@emotion/react';

export const styles = {
  header: css`
    display: flex;
    width: 100%;
    height: 80px;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
  `,
  logo: css`
    width: 50px;
    height: 50px;
    background: #eee;
  `,
  loginWrapper: css`
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.05) 0%,
        rgba(255, 255, 255, 0.05) 100%
      ),
      #121212;
    padding: 10px 8px 10px 16px;
    border-radius: 8px;
  `,
  loginBtn: css`
    height: 28px;
    background-color: #5865f2;
    color: #fff;
    box-shadow: none;
    margin-right: 12px;
    font-size: 12px;
    font-weight: 500;

    &:hover {
      background-color: #5865f2;
      border-color: #5865f2;
      box-shadow: none;
    }

    &:active {
      box-shadow: none;
      background-color: #5865f2;
      border-color: #5865f2;
    }

    &:focus {
      box-shadow: none;
    }
  `,
};
