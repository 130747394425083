import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Alert, AlertTitle, Backdrop, CircularProgress } from '@mui/material';

import axios from 'axios';

import Container from '../../components/Container/Container.component';
import supabaseClient from '../../core/services/supabase/supabase.service';
import { API } from '../../shared/api';
import { getAxiosConfigWithBearerToken } from '../../shared/constants';
import { useFetch } from '../../shared/hooks';

type Vendor = {
  id: number;
};

interface IData {
  id: string;
  created_at: string;
  currency: string;
  payload: [ref_id: string, discord_id: string, amount: string];
  quantity: number;
  total_amount: number;
}

function History() {
  const { t } = useTranslation();
  const [vendor, setVendor] = useState<Vendor>();
  const [data, setData] = useState<IData[]>([]);

  async function getVendorData() {
    const { data, error } = await supabaseClient
      .from('profiles')
      .select('vendor_id')
      .single();
    if (error) alert(`Unexpected Error: ${error.message}`);
    else {
      setVendor({id: data.vendor_id})
    }
  }

  useEffect(() => {
    getVendorData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { isFetching } = useFetch('disbursement-history', API.VENDOR_DISBURSEMENT_COMPLETED + vendor?.id, {
    options: {
      enabled: Boolean(vendor?.id),
      onSuccess: (res: any) => {
        if (res.success) setData(res.data)
      }
    }
  })

  return (
    <Container>
      <div className="mt-4 mb-12">
        <div className="mb-8">
          <div className="text-white text-2xl md:text-5xl font-VT323">
            {t('Transaction History')}
          </div>
          <div className="text-gray-200 text-sm md:text-md">
            {t('List of successfully disbursed transactions')}
          </div>
        </div>
        {isFetching && (
          <div className="max-w-md mx-auto flex justify-center my-8">
             <CircularProgress />
          </div>
        )}
        <div className="max-w-md mx-auto">
          {data.length > 0 ? (
            data.map(data => {
              return (
                <div
                  key={data.id}
                  className="relative grid grid-cols-4 gap-4 my-2 px-2 py-4 rounded-lg bg-stone-800"
                >
                  <div className="absolute top-0 right-0 mt-2 mr-2 py-1 px-2 text-green-300 text-sm">
                    {data.id.substring(0, 6)}
                  </div>
                  <div className="p-2 object-contain rounded-lg bg-stone-700">
                    <img
                      src={`/img/${data.currency.toLowerCase()}.png`}
                      alt={data.currency}
                      className="md:h-12 mx-auto"
                    />
                  </div>
                  <div className="flex flex-col col-span-3">
                    <div className="text-gray-200 text-lg font-medium">
                      {data.total_amount.toLocaleString('en')} {data.currency}
                    </div>
                    <div className="text-gray-300 text-sm">
                      {data.quantity} {t('Recipients')}
                    </div>
                    <div className="text-gray-400 text-xs">
                      {new Date(data.created_at).toLocaleString()}
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <Alert severity="warning">
              <AlertTitle>{t('No Data')}</AlertTitle>
              {t("You don't have any transaction history, yet.")}
            </Alert>
          )}
        </div>
      </div>
    </Container>
  );
}

export default History;
