import '@fontsource/vt323';
import { useEffect } from 'react';

import supabaseClient from '../core/services/supabase/supabase.service';
import { useAuthStore } from '../core/zustand/store/authStore/authStore';
import { IApp } from './App.config';
import './App.css';

const App = function ({ children }: IApp) {
  const { setUser, setAccessToken } = useAuthStore();

  useEffect(() => {
    supabaseClient.auth.getSession().then(({ data: { session } }) => {
      if (session !== null && session?.user !== null && session?.user.role === 'authenticated') {
        supabaseClient.auth.signOut().then(() => location.replace('https://app.envoyportal.com'))
      }
      setUser(session?.user ?? null);
      setAccessToken(session?.access_token ?? null)
    })

    const listener = supabaseClient.auth.onAuthStateChange((event, session) => {
      if (event === 'SIGNED_OUT') {
        setUser(null)
        setAccessToken(null)
      };
      if (event === 'SIGNED_IN') {
        setUser(session?.user ?? null);
        setAccessToken(session?.access_token ?? null);
      };
    });

    // Cleanup function to unsubscribe when the component unmounts
    return () => {
      listener?.data?.subscription?.unsubscribe();
    };
  }, [supabaseClient]);


  return children;
};

export default App;
