import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputAdornment,
  Skeleton,
  TextField,
} from '@mui/material';

import Container from '../../../components/Container/Container.component';
import ConfirmModal from '../../../components/Modals/ConfirmModal.component';
import { API } from '../../../shared/api';
import { money } from '../../../shared/constants';
import { useFetch, useMutation } from '../../../shared/hooks';
import { AcceptTransaction, DisbursementPayload, MutationHttpMethod } from '../../../shared/types';
import { toast } from 'react-hot-toast';

interface IInvoice {
  id: string;
  disbursement_id: {
    id: string;
    usd_rate: number | null;
    posted?: string[] | null;
    completed?: string[] | null;
    postponed?: DisbursementPayload[] | null;
  };
  amount: number;
  currency: string;
  address: string;
  tx_id: string;
  network: string;
}

interface IInputs {
  tx_id?: string;
  reference?: string;
  usdt_tx_id?: string;
  dep_tx_id?: string;
  usd_rate?: number;
}

interface builtData {
  [key: string]: AcceptTransaction[] | DisbursementPayload[] | string[] | undefined;
  DEP?: AcceptTransaction[];
  USDT?: AcceptTransaction[];
  IDR?: AcceptTransaction[];
  errors?: string[];
  no_setting?: DisbursementPayload[];
}

const accumulate = (data: AcceptTransaction[]) => {
  return data.reduce((sum, { amount }) => {
    return Number(sum) + Number(amount);
  }, 0);
};

export default function DisbursementInvoiceApproval() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [openApproveModal, setOpenApproveModal] = useState(false);
  const [openDialogs, setOpenDialogs] = useState({
    confirmInvoice: false,
    confirmUSDT: false,
    confirmXendit: false,
    confirmDEP: false,
  })
  const [inputs, setInputs] = useState<IInputs>();
  const [builtData, setBuiltData] = useState<builtData>({});

  const USDTtotals = useMemo(() => {
    const data = builtData?.USDT;
    const net = data ? accumulate(data) : 0;
    const fees = data ? data?.length * 0.5 : 0;
    return {
      net: net,
      fees: fees,
      total: net + fees,
    }
  }, [builtData.USDT]);

  const IDRtotals = useMemo(() => {
    const data = builtData?.IDR;
    const net = data ? accumulate(data) : 0;
    const fees = data ? data?.length * 5500 : 0;
    return {
      net: net,
      fees: fees,
      total: net + fees,
    }
  }, [builtData.IDR]);

  const DEPtotals = useMemo(() => {
    const data = builtData?.DEP;
    const net = data ? accumulate(data) : 0;
    const fees = net * 0.03
    return {
      net: net,
      fees: fees,
      total: net + fees,
    }
  }, [builtData.DEP]);

  const { invoice_id } = useParams();

  const allowedStables = ['USDT'];
  
  const { data: disbursementInv, refetch, isFetching: dataFetching } = useFetch(
    `disbursement-data-${invoice_id}`,
    `${API.ADMIN_DISBURSEMENT_INVOICE}?invoice_id=${invoice_id}`,
  )

  const data = disbursementInv?.data as IInvoice;
  const disbursement_id = data ? data.disbursement_id.id : '';
  const posted = data?.disbursement_id.posted || [];
  const completed = data?.disbursement_id.completed || [];
  const postponed = data?.disbursement_id.postponed || [];

  useEffect(() => {
    const usd_rate = data?.disbursement_id.usd_rate || 0;
    setInputs(prev => ({...prev, usd_rate}))
  }, [data])

  const postedURL = `${API.ADMIN_DISBURSEMENT_GET_POSTED_DATA}?currency=${posted.join(',')}&disbursement_id=${disbursement_id}`
  useFetch('posted-disbursements', postedURL, {
    options: {
      enabled: Boolean(posted.length),
      onSuccess: (res: builtData) => {
        setBuiltData(prev => {
          return {
            ...prev,
            ...res,
          };
        });
      },
    },
  });

  const reject = useMutation(`${API.ADMIN_DISBURSEMENT_REJECT}/${disbursement_id}`, {
    options: {
      enabled: Boolean(disbursement_id),
      onSuccess: () => {
        navigate('/portal/vendor/disbursements');
      }
    }
  }, MutationHttpMethod.DELETE);

  async function rejectDisbursement() {
    await reject.mutateAsync({});
  }

  const confirmInvoiceTxID = useMutation(API.ADMIN_DISBURSEMENT_CONFIRM_TXID, {
    options: {
      enabled: Boolean(inputs?.tx_id),
      onSuccess: () => {
        refetch();
        setOpenDialogs(prev => ({...prev, confirmInvoice: false}));
      }
    }
  }, MutationHttpMethod.PATCH)

  async function confirmTxID() {
    const body = {
      invoice_id: invoice_id,
      tx_id: inputs?.tx_id,
    };
    await confirmInvoiceTxID.mutateAsync(body);
  }

  const setUSD = useMutation(API.ADMIN_DISBURSEMENT_USD_RATE, {
    options: {
      enabled: Boolean(disbursement_id) && Boolean(inputs?.usd_rate),
      onSuccess: () => {
        refetch();
      },
    },
  }, MutationHttpMethod.PATCH);

  async function setUSDrate() {
    const body = {
      disbursement_id: disbursement_id,
      usd_rate: inputs?.usd_rate,
    };
    await setUSD.mutateAsync(body);
  }

  const build = useMutation(API.ADMIN_DISBURSEMENT_BUILD, {
    options: {
      enabled: Boolean(disbursement_id),
      onSuccess: (res: builtData) => {
        setBuiltData(prev => {
          return {
            ...prev,
            ...res,
          };
        });
      },
    }
  })

  const buildData = async () => {
    await build.mutateAsync({ disbursement_id });
  }

  const acceptTransaction = useMutation(API.ADMIN_DISBURSEMENT_POST)

  async function postAcceptTransaction(currency: string) {
    if (!currency || !builtData || disbursement_id === '') return false;
    
    const source = builtData[currency];
    const body = {
      data: source,
      disbursement_id: disbursement_id,
      currency: currency,
    };
    acceptTransaction.mutateAsync(body)
      .then(() => {
        refetch();
        toast.success(`${currency} Posted!`);
      })
      .catch(err => toast.error(err));
  }

  const markTransaction = useMutation(API.ADMIN_DISBURSEMENT_TRANSACTION_MARK_COMPLETE, {
    options: {
      enabled: Boolean(disbursement_id),
    }
  }, MutationHttpMethod.PATCH)

  async function markTransactionsComplete(currency: string, tx_id = '') {
    if (!currency) return false;
    
    const body = {
      disbursement_id: disbursement_id,
      currency: currency,
      tx_id: tx_id === '' ? null : tx_id,
    };
    markTransaction.mutateAsync(body)
      .then(() => {
        toast.success(`${currency} Marked Complete`);
      })
      .catch(err => toast.error(err))
      .finally(() => setOpenDialogs(prev => ({...prev, confirmUSDT: false, confirmDEP: false})));
  }

  const markDisbursement = useMutation(API.ADMIN_DISBURSEMENT_MARK_COMPLETE, {}, MutationHttpMethod.PATCH);

  async function markDisbursementComplete() {
    if (disbursement_id === '') return;
    const body = {
      disbursement_id: disbursement_id,
    };
    markDisbursement.mutateAsync(body)
      .then(() => navigate('/portal/vendor/disbursements'))
      .catch(err => toast.error(err));
  }

  const xendit = useMutation(API.ADMIN_XENDIT_CREATE_BATCH_DISBURSEMENT);

  async function postIDRdataToXendit() {
    if (disbursement_id === '') return false;
    const body = {
      disbursement_id: disbursement_id,
      reference: inputs?.reference,
    };
    xendit.mutateAsync(body)
      .then(() => toast.success('Posted to Xendit'))
      .catch(error => toast.error(error))
      .finally(() => setOpenDialogs(prev => ({...prev, confirmXendit: false})));
  }

  const postpone = useMutation(API.ADMIN_DISBURSEMENT_POSTPONE, { options: { onSuccess: () => refetch() } });

  const postponeNoSettings = async () => {
    const payload = builtData?.no_setting ?? [];
    if (payload.length < 1) return false;
    postpone.mutateAsync({ disbursement_id, payload })
      .then(() => refetch())
      .catch(error => toast.error(error));
  }

  const validateUSDT = useMutation(API.ADMIN_DISBURSEMENT_VALIDATE_TOKEN_TRANSFER, {
    options: { onSuccess: (data: any) => {
      if (data.error) {
        toast.error(data.message)
      } else {
        toast.success('Validated!')
      }
    } }
  })

  const handleValidateUSDT = async () => {
    if (!builtData.USDT) {
      toast.error('No USDT Data')
      return false;
    }
    validateUSDT.mutate({ items: builtData.USDT, network: 'BSC', token: 'USDT' })
  }

  const executeUSDTTransfer = useMutation(API.ADMIN_DISBURSEMENT_EXECUTE_TOKEN_TRANSFER, {
    options: { onSuccess: (data: any) => {
      if (data.error) {
        toast.error(data.message)
      } else {
        toast.success('Transferred!')
        refetch();
      }
    } }
  })

  const handleExecuteUSDTTransfer = async () => {
    if (!builtData.USDT) {
      toast.error('No USDT Data')
      return false;
    }
    executeUSDTTransfer.mutate({ items: builtData.USDT, network: 'BSC', token: 'USDT' })
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;
    setInputs(prevState => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  }

  return (
    <Container>
      <div className="mt-4 mb-12">
        <div>
          <div className="text-white text-2xl md:text-5xl font-VT323">
            {t('Disbursement Invoice Approval')}
          </div>
          <div className="text-gray-200 text-sm md:text-md">
            {t('Are you sure everything is correct?')}
          </div>
        </div>

        <div className="rounded-md bg-neutral-900 p-4 mt-8 mb-8 mx-auto overflow-auto">
          <div
            className="text-stone-100 md:font-bold text-lg md:text-xl"
            onClick={() => navigate('/portal/vendor/disbursements')}
          >
            {t('← Back')}
          </div>

          <>
          <div className="grid grid-cols-1 mt-6 gap-4 max-w-md mx-auto">
            {dataFetching ? <Skeleton width={400} height={60} /> : <TextField
              label="Disbursement ID"
              defaultValue={data?.disbursement_id.id}
              InputProps={{
                readOnly: true,
              }}
              fullWidth
            />}
            {dataFetching ? <Skeleton width={400} height={60} /> : <TextField
              label="Invoice ID"
              defaultValue={data?.id}
              InputProps={{
                readOnly: true,
              }}
              fullWidth
            />}
            {dataFetching ? <Skeleton width={400} height={60} /> : <TextField
              label="Payment Address"
              defaultValue={data?.address}
              InputProps={{
                readOnly: true,
              }}
              fullWidth
            />}
            {dataFetching ? <Skeleton width={400} height={60} /> : <div className="grid grid-cols-3 gap-3">
              <TextField
                label="Amount"
                defaultValue={data?.amount}
                InputProps={{
                  readOnly: true,
                }}
              />
              <TextField
                label="Currency"
                defaultValue={data?.currency}
                InputProps={{
                  readOnly: true,
                }}
              />
              <TextField
                label="Network"
                defaultValue={data?.network}
                InputProps={{
                  readOnly: true,
                }}
              />
            </div>}
          </div>
          <div className="mt-6 mx-auto text-center">
            {data?.tx_id ? (
              <>
                <div className="text-lg text-green-500">
                  {t('Payment Confirmed')}
                </div>
                <div className="text-sm text-stone-200">
                  {t('Tx ID: {{txId}}', { txId: data?.tx_id })}
                </div>
              </>
            ) : (
              <div className="text-lg text-red-500">
                {t('Payment Unconfirmed')}
              </div>
            )}
          </div>
          </>
          
          {data && !allowedStables.includes(data?.currency) && (
            <div className="mt-4 flex flex-col">
              <div className="text-stone-300 text-center">
                {t('{{currency}} → USD Rate', { currency: data.currency })}
              </div>
              <div className="flex justify-center space-x-4 items-center">
                <TextField
                  margin="normal"
                  label="USD Rate"
                  type="text"
                  name="usd_rate"
                  error={inputs?.usd_rate === 0}
                  value={inputs?.usd_rate}
                  onChange={handleChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                />
                <div>
                  <LoadingButton
                    onClick={setUSDrate}
                    variant="text"
                    size="small"
                    color="warning"
                    loading={setUSD.isLoading}
                  >
                    {t('Update Rate')}
                  </LoadingButton>
                </div>
              </div>
            </div>
          )}
          <div className="mt-4 flex justify-center mx-auto space-x-2">
            {!data?.tx_id && (
              <>
                <Button
                  variant="outlined"
                  color="error"
                  onClick={() => setOpenConfirmModal(true)}
                >
                  {t('Reject')}
                </Button>
                <Button
                  variant="outlined"
                  color="success"
                  onClick={() => setOpenDialogs(prev => ({...prev, confirmInvoice: true}))}
                >
                  {t('Confirm')}
                </Button>
              </>
            )}
            <LoadingButton
              variant="contained"
              disabled={!data?.tx_id}
              onClick={buildData}
              loading={build.isLoading}
            >
              {t('Build')}
            </LoadingButton>
            <Button
              variant="contained"
              color="success"
              disabled={!data?.tx_id}
              onClick={() => setOpenApproveModal(true)}
            >
              {t('Mark Done')}
            </Button>
          </div>

          {builtData?.IDR && builtData?.IDR.length > 0 && (
            <div className="mt-6 flex flex-col bg-gray-900 rounded-lg py-4 px-6">
              <div className="flex my-4 space-x-4 mx-auto items-center">
                <div className="text-lg font-semibold text-stone-100">
                  {t('IDR Disbursements')}
                </div>
                <LoadingButton
                  variant="outlined"
                  color="success"
                  onClick={() => postAcceptTransaction('IDR')}
                  loading={acceptTransaction.isLoading}
                  disabled={posted.includes('IDR')}
                >
                  {posted.includes('IDR') ? 'Posted' : 'Post'}
                </LoadingButton>
                {posted.includes('IDR') && (
                  <>
                    <LoadingButton
                      variant="outlined"
                      color="success"
                      onClick={() => markTransactionsComplete('IDR')}
                      loading={markTransaction.isLoading}
                      disabled={completed.includes('IDR')}
                    >
                      {t('Mark as Done')}
                    </LoadingButton>
                    <Button
                      variant="outlined"
                      onClick={() => setOpenDialogs(prev => ({...prev, confirmXendit: true}))}
                      disabled={completed.includes('IDR')}
                    >
                      {t('Post to Xendit')}
                    </Button>
                  </>
                )}
              </div>
              <div className="text-stone-200 my-2 italic text-lg mx-auto">
                {t(
                  'Net Total: {{netTotal}} - Fees: {{fee}}  - Total: {{total}}',
                  {
                    netTotal: money(IDRtotals.net),
                    fee: money(IDRtotals.fees),
                    total: money(IDRtotals.total),
                  },
                )}
              </div>
              <div className="grid grid-cols-7 my-2 gap-4 text-stone-100 font-medium">
                <div className="col-span-2">{t('Ref_id')}</div>
                <div className="col-span-2">{t('Acc Name')}</div>
                <div className="col-span-2">{t('Acc No.')}</div>
                <div className="text-right">{t('Amount')}</div>
              </div>
              {builtData?.IDR.map(item => {
                return (
                  <div
                    key={item.ref_id}
                    className="grid grid-cols-7 my-1 gap-4 text-stone-200"
                  >
                    <div className="col-span-2">{item.ref_id}</div>
                    <div className="col-span-2">{item.account_name}</div>
                    <div className="col-span-2">
                      {item.service_name} {item.account_number}
                    </div>
                    <div className="text-right">
                      {item.amount.toLocaleString('en-GB')}
                    </div>
                  </div>
                );
              })}
            </div>
          )}

          {/* USDT */}
          {builtData?.USDT && builtData?.USDT.length > 0 && (
            <div className="mt-6 flex flex-col bg-stone-900 rounded-lg py-4 px-6">
              <div className="flex my-4 space-x-4 mx-auto items-center">
                <div className="text-lg font-semibold text-stone-100">
                  {t('USDT Disbursements')}
                </div>
                <LoadingButton
                  variant="outlined"
                  color="success"
                  onClick={() => postAcceptTransaction('USDT')}
                  loading={acceptTransaction.isLoading}
                  disabled={posted.includes('USDT')}
                >
                  {posted.includes('USDT') ? 'Posted' : 'Post'}
                </LoadingButton>
                
                {posted.includes('USDT') && (
                  <>
                    <LoadingButton
                      variant="outlined"
                      color="success"
                      onClick={handleValidateUSDT}
                      loading={validateUSDT.isLoading}
                      disabled={dataFetching || completed.includes('USDT')}
                    >
                      Validate
                    </LoadingButton>
                    <LoadingButton
                      variant="outlined"
                      color="success"
                      onClick={handleExecuteUSDTTransfer}
                      loading={executeUSDTTransfer.isLoading}
                      disabled={dataFetching || completed.includes('USDT')}
                    >
                      Transfer
                    </LoadingButton>
                  </>
                )}
              </div>
              <div className="text-stone-200 my-2 italic text-lg mx-auto">
                {t(
                  'Net Total: {{netTotal}} - Fees: {{fee}}  - Total: {{total}}',
                  {
                    netTotal: USDTtotals.net.toFixed(2),
                    fee: USDTtotals.fees.toFixed(2),
                    total: USDTtotals.total.toFixed(2),
                  },
                )}
              </div>
              <div className="grid grid-cols-7 my-2 text-stone-100 font-medium">
                <div className="col-span-2">{t('Ref_id')}</div>
                <div>{t('Amount')}</div>
                <div className="col-span-4">{t('Address')}</div>
              </div>
              {builtData?.USDT.map(item => {
                return (
                  <div
                    key={item.ref_id}
                    className="grid grid-cols-7 my-1 text-stone-200"
                  >
                    <div className="col-span-2">{item.ref_id}</div>
                    <div>
                      {item.amount} {item.currency}
                    </div>
                    <div className="col-span-4">{item.address}</div>
                  </div>
                );
              })}
            </div>
          )}

          {/* DEP */}
          {builtData?.DEP && builtData?.DEP.length > 0 && (
            <div className="mt-6 flex flex-col bg-yellow-500/50 rounded-lg py-4 px-6">
              <div className="flex my-4 space-x-4 mx-auto items-center">
                <div className="text-lg font-semibold text-stone-100">
                  {t('DEP List')}
                </div>
                <LoadingButton
                  variant="outlined"
                  color="success"
                  onClick={() => postAcceptTransaction('DEP')}
                  loading={acceptTransaction.isLoading}
                >
                  {t('Post')}
                </LoadingButton>
                {posted.includes('DEP') && (
                  <Button
                    variant="outlined"
                    color="success"
                    onClick={() => setOpenDialogs(prev => ({...prev, confirmDEP: true}))}
                    disabled={completed.includes('DEP')}
                  >
                    {completed.includes('DEP') ? 'Done' : 'Mark Done'}
                  </Button>
                )}
              </div>
              <div className="text-stone-200 my-2 italic text-lg mx-auto">
                {t(
                  'Net Total: {{netTotal}} - Fees: {{fee}}  - Total: {{total}}',
                  {
                    netTotal: DEPtotals.net,
                    fee: DEPtotals.fees,
                    total: DEPtotals.total,
                  },
                )}
              </div>
              <table>
                <thead>
                  <tr>
                    <th>Ref ID</th>
                    <th>Amount</th>
                    <th>PMID</th>
                  </tr>
                </thead>
                <tbody>
                  {builtData?.DEP.map(item => (
                    <tr key={item.ref_id}>
                      <td>{item.ref_id}</td>
                      <td>{item.amount}</td>
                      <td>{item.address}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
          {builtData?.errors && builtData?.errors.length > 0 && (
            <div className="mt-6 flex flex-col bg-red-900/50 rounded-lg py-4 px-6">
              <div className="flex my-4 space-x-4 mx-auto items-center">
                <div className="text-lg font-semibold text-stone-100">
                  {t('Errors')}
                </div>
              </div>
              <div className="my-2 text-stone-100 font-medium">
                <div>{t('Discord ID')}</div>
              </div>
              {builtData?.errors.map(item => {
                return (
                  <div
                    key={item}
                    className="grid grid-cols-1 my-1 text-stone-200"
                  >
                    <div>{item}</div>
                  </div>
                );
              })}
            </div>
          )}
          {builtData?.no_setting && builtData?.no_setting.length > 0 && (
            <div className="mt-6 flex flex-col bg-red-300/50 rounded-lg py-4 px-6">
              <div className="flex my-4 space-x-4 mx-auto items-center">
                <div className="text-lg font-semibold text-stone-100">
                  Scholars with No Setting
                </div>
                <LoadingButton
                  variant="outlined"
                  color="success"
                  onClick={postponeNoSettings}
                  loading={postpone.isLoading}
                >
                  Postpone
                </LoadingButton>
              </div>
              <table>
                <thead>
                  <tr>
                    <th>Ref ID</th>
                    <th>Discord ID</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {builtData?.no_setting.map(item => (
                    <tr key={item.ref_id}>
                      <td>{item.ref_id}</td>
                      <td>{item.discord_id}</td>
                      <td>{item.amount}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          {/* Postponed */}
          {data && postponed.length > 0 && (
            <div className="mt-6 flex flex-col bg-indigo-300/50 rounded-lg py-4 px-6">
              <div className="flex my-4 space-x-4 mx-auto items-center">
                <div className="text-lg font-semibold text-stone-100">
                  Postponed Payload
                </div>
              </div>
              <table>
                <thead>
                  <tr>
                    <th>Ref ID</th>
                    <th>Discord ID</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {postponed.map(item => (
                    <tr key={item.ref_id}>
                      <td>{item.ref_id}</td>
                      <td>{item.discord_id}</td>
                      <td>{item.amount}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
        <ConfirmModal
          open={openConfirmModal}
          setOpen={setOpenConfirmModal}
          loading={reject.isLoading}
          modalTitle="Are you sure?"
          modalDescription="Please confirm if you really want to REJECT this disbursement"
          actionHandler={rejectDisbursement}
          actionButton
          actionButtonLabel="REJECT"
          cancelButton
          cancelButtonLabel="No!"
        />
        <ConfirmModal
          open={openApproveModal}
          setOpen={setOpenApproveModal}
          loading={markDisbursement.isLoading}
          modalTitle="Are you sure?"
          modalDescription="Do you really want to Mark this disbursement as Complete?"
          actionHandler={markDisbursementComplete}
          actionButton
          actionButtonLabel="Complete"
          cancelButton
          cancelButtonLabel="Not, yet!"
        />
        {/* Confirm Post to Xendit Dialog */}
        <Dialog
          open={openDialogs.confirmXendit}
          onClose={() => setOpenDialogs(prev => ({...prev, confirmXendit: false}))}
        >
          <DialogTitle>{t('Post to Xendit?')}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {t(
                'Enter the Xendit Batch Disbursement Reference for this batch.',
              )}
            </DialogContentText>
            <TextField
              autoFocus
              margin="normal"
              label="Reference"
              name="reference"
              type="text"
              value={inputs?.reference}
              onChange={handleChange}
              fullWidth
              variant="outlined"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDialogs(prev => ({...prev, confirmXendit: false}))}>
              {t('Cancel')}
            </Button>
            <LoadingButton
              onClick={postIDRdataToXendit}
              variant="contained"
              loading={xendit.isLoading}
            >
              {t('Confirm')}
            </LoadingButton>
          </DialogActions>
        </Dialog>

        {/* Confirm Invoice Transfer by tx_id Dialog */}
        <Dialog
          open={openDialogs.confirmInvoice}
          onClose={() => setOpenDialogs(prev => ({...prev, confirmInvoice: false}))}
        >
          <DialogTitle>{t('Confirm Payment')}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {t(
                'To confirm this invoice payment, please input the tx_id below.',
              )}
            </DialogContentText>
            <TextField
              autoFocus
              margin="normal"
              label="TxID"
              name="tx_id"
              type="text"
              value={inputs?.tx_id}
              onChange={handleChange}
              fullWidth
              variant="outlined"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDialogs(prev => ({...prev, confirmInvoice: false}))}>
              {t('Cancel')}
            </Button>
            <LoadingButton
              onClick={confirmTxID}
              variant="contained"
              loading={confirmInvoiceTxID.isLoading}
            >
              {t('Confirm')}
            </LoadingButton>
          </DialogActions>
        </Dialog>

        {/* Mark USDT Transfers Done Dialog */}
        <Dialog
          open={openDialogs.confirmUSDT}
          onClose={() => setOpenDialogs(prev => ({...prev, confirmUSDT: false}))}
        >
          <DialogTitle>{t('Confirm Transfers')}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {t(
                'To confirm this USDT Transfers, please input the tx_id below.',
              )}
            </DialogContentText>
            <TextField
              autoFocus
              margin="normal"
              label="TxID"
              name="usdt_tx_id"
              type="text"
              value={inputs?.usdt_tx_id}
              onChange={handleChange}
              fullWidth
              variant="outlined"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDialogs(prev => ({...prev, confirmUSDT: false}))}>
              {t('Cancel')}
            </Button>
            <LoadingButton
              onClick={() => markTransactionsComplete('USDT', inputs?.usdt_tx_id)}
              variant="contained"
              loading={markTransaction.isLoading}
            >
              {t('Confirm')}
            </LoadingButton>
          </DialogActions>
        </Dialog>

        {/* Mark Crypto Transfers Done Dialog */}
        <Dialog
          open={openDialogs.confirmDEP}
          onClose={() => setOpenDialogs(prev => ({...prev, confirmDEP: false}))}
        >
          <DialogTitle>{t('Confirm Transfers')}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {t(
                'To confirm this Crypto Transfers, please input the tx_id below.',
              )}
            </DialogContentText>
            <TextField
              autoFocus
              margin="normal"
              label="TxID"
              name="dep_tx_id"
              type="text"
              value={inputs?.dep_tx_id}
              onChange={handleChange}
              fullWidth
              variant="outlined"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDialogs(prev => ({...prev, confirmDEP: false}))}>
              {t('Cancel')}
            </Button>
            <LoadingButton
              onClick={() => markTransactionsComplete('DEP', inputs?.dep_tx_id)}
              variant="contained"
              loading={markTransaction.isLoading}
            >
              {t('Confirm')}
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </div>
    </Container>
  );
}
